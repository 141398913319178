import * as XLSX from "xlsx";
import AgencyRow, {RawAgencyRow} from "./agency";


export default async function parseGetYourGuideFile(file: File | Blob): Promise<AgencyRow[]> {
  const parsed = await parseFileData(file);
  const aggregation = parsed.reduce((result, row) => {
    const agency = 'GG';
    const agencyCode = cleanString(row['Booking Ref #']);
    if (!agencyCode) return result;
    const date = dateToDash(row['Date']);
    const koProductName = /\[(.+)\]/.exec(row['Option'] ?? '')?.[1];
    const product = (cleanString(row['Product']) + ' ' + cleanString(row['Option'])) + (koProductName ? ` ${koProductName}` : '');
    const option = cleanString(row['Option']);
    const clientName = cleanString(row['Traveler\'s First Name'] + ' ' + cleanString(row['Traveler\'s Last Name']));

    const adult = toNumber(row['Adult']);
    const senior = toNumber(row['Senior']);
    const student = toNumber(row['Student (with ID)']);
    const euCitizens = toNumber(row['EU Citizens (with ID)']);
    const euStudent = toNumber(row['Student EU Citizens (with ID)']);
    const military = toNumber(row['Military (with ID)']);
    const youth = toNumber(row['Youth']);
    const children = toNumber(row['Children']);
    const infant = toNumber(row['Infant']);

    const people = adult + senior + student + euCitizens + euStudent + military + youth + children + infant
    const tel = cleanString(row['Phone'])


    const pickupPlace = option;

    const addOnOptions= cleanString(row['Add-ons'])
      .split('\n')
      .map(addOn=> {
        const regexResults = /(\d+) x (.+)/.exec(addOn);
        console.log(regexResults);
        const option = regexResults?.[2];
        const count = toNumber(regexResults?.[1] ?? '0');
        if(option && count ){
          return {option:cleanString(option), people:count};
        }
        return null;
      })
      .filter(x=>!!x) as {option:string, people:number}[];

    const language = cleanString(row['Language']).replace(/Chinese.*/gi, 'CN').replace(/English.*/gi, 'EN');

    const options:{option:string, people:number}[] = [{option, people}]
      .concat(addOnOptions)
      .filter(o => o.option && o.people)


    const obj: RawAgencyRow = {
      agency,
      agencyCode,
      clientName,
      language,
      product,
      date,
      people,
      tel,
      pickupPlace,
      stroller: 'X',
      options,
    };

    result[agencyCode] = obj;
    return result;
  }, {} as {
    [agencyCode: string]: RawAgencyRow
  });
  return Object.values(aggregation);
}

const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array"})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  const data = XLSX.utils.sheet_to_json(firstSheet) as any[];
  return data;
}

function dateToDash(datelike: number) {
  const date = new Date(Date.UTC(0, 0, datelike - 1));
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  return `${year}-${month}-${day}`;
}

function cleanString(data?: any): string {
  const escaped = data?.toString().replace(/.+[\]】]/gi, '');
  return escaped?.trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}


//엘리시안, 남이엘리시안, 어비비발디, 비주스, 남이비발디

const SKI_PRODUCTS: { [possible: string]: string } = {
  'Eden Valley Winter Ski Day Tour from Busan | Korea': '에덴',
  'Jisan Forest Winter Ski Day Tour from Seoul | Korea': '지산',
  'Jisan Forest Ski Day Tour from Seoul | Korea': '지산',
  'Jisan Forest Winter Ski Day Tour from Seoul  | Korea':'지산',
  'Elysian Gangchon Ski Resort One Day Tour with Basic Ski Lesson | Korea': '엘리시안',
  'Gangwon 1-Day Tour: Elysian Gangchon Ski Resort & Nami Island | South Korea': '남이엘리시안',
  'Korea Winter Ski Experience: Elysian Gangchon Ski Resort Day Tour from Seoul': '엘리시안',
  'Elysian Gangchon Ski Resort & Nami Island Day Tour from Seoul | South Korea': '남이엘리시안',
  'Hongcheon Daemyung Vivaldi Park Ski World + Eobi Ice Valley': '어비비발디',
  'Hongcheon Daemyung Vivaldi Park Ski World + Nami Island': '남이비발디',

  '韩国伊利希安江村度假村滑雪场一日游【距离首尔近 • 新手欢迎】': '엘리시안',
  '韩国春川市伊利希安江村度假村滑雪场+南怡岛一日游【距离首尔近 • 新手欢迎】': '남이엘리시안',
  '韩国洪川郡大明维瓦尔第SONO滑雪场+南怡岛一日游【中文教练 • 入门教学 • 多个方案选择】': '남이비발디',
  '韩国洪川郡大明维瓦尔第SONO滑雪场+南怡岛一日游【多个方案选择 • 入门教学】': '남이비발디',
  '韩国洪川郡大明维瓦尔第SONO滑雪场+鱼飞溪谷一日游【入门教学 • 多个方案选择】': '어비비발디',
  '韩国京畿道芝山森林度假村滑雪场一日游【中文教練•首尔近郊•初学者适合】': '지산',
  '韩国京畿道芝山森林度假村滑雪场一日游【首尔近郊 • 适合初学者】': '지산',
  '韩国釜山伊甸公园一日游【伊甸园山谷滑雪度假村•中文教練•釜山近郊•初学者适合】': '에덴',
  '韩国釜山伊甸谷度假村一日游【伊甸园山谷滑雪度假村 • 釜山近郊 • 初学者适合】': '에덴',

  '엘리시안':'엘리시안',
  '남이엘리시안':'남이엘리시안',
  '남이비발디':'남이비발디',
  '어비비발디':'어비비발디',
  '지산':'지산',
  '에덴':'에덴'
}

const SKI_OPTIONS: { [agency: string]: { [product: string]: { [optionPossible: string]: string[] } } } = {
  'KK': {
    '에덴': {
      'A. Transportation shuttle bus to and from the ski resort (available for those aged 3 and above)': ['A : Shuttle (Transport Only)'],
      'B. Skiing (Ski + 1 lift + ski outfit) (available for those aged 7 and above)': ['B : Ski + Lift(1 time) + Ski Clothes(jacket, pants)'],
      'C. Snowboard (Snowboard + 1 lift + ski outfit) (available for those aged 7 and above)': ['C : Snowboard + Lift(1 time) + Ski Clothes(jacket, pants)'],
      'D-1: Sightseeing only': ['D : Sightseeing Only'],
      'D-2 : Sled': ['E : Sled'],
      'E. Skiing (Ski + lift 5Hrs + ski outfit) (available for those aged 7 and above)': ['F : Ski + Lift Pass (5-Hour) + Ski Clothes (Jacket, pants)'],
      'F. Snowboard (Snowboard + lift 5Hrs + ski outfit) (available for those aged 7 and above)': ['G : Snowboard + Lift Pass (5-Hour) + Ski Clothes (Jacket, pants)'],
      'G. [Additional] Basic Group Courses': ['Basic Group Lesson(1Hr)'],
      'Basic group courses': ['Basic Group Lesson(1Hr)'],
      'Basic group course': ['Basic Group Lesson(1Hr)'],
      'None': ['Ignore'],
      'D. Non-ski package (sightseeing/sledding only) (available for ages 3 and above)': ['Ignore']
    },
    '지산': {
      'A. Suhttle to and from the Ski Resort (for those aged 3 and above)': ['A : Shuttle (Transport Only)'],
      'B-1: Ski + Moving Walk + Ski Clothes & Pants': ['B : Ski + Moving Walk Pass + Ski Clothes(jacket, pants)'],
      'B-2: Ski + Moving Walk + Lift + Ski Clothes & Pants': ['C : Ski + Moving Walk Pass + Lift Pass + Ski Clothes(jacket, pants)'],
      'C-1: Snowboard + Moving Walk + Ski Clothes & Pants': ['D : Snowboard + Moving Walk Pass + Ski Clothes(jacket, pants)'],
      'C-2: Snowboard + Moving Walk + Lift + Ski Clothes & Pants': ['E : Snowboard + Moving Walk Pass + Lift Pass + Ski Clothes(jacket, pants)'],
      'C-2: Snowboard + Moving + Lift + Ski Clothes' : ['E : Snowboard + Moving Walk Pass + Lift Pass + Ski Clothes(jacket, pants)'],
      'D. Non-skiing (sled) (available for ages 3 and abov)': ['F : Sled'],
      'Basic group lesson': ['G: Basic Group Lesson(1Hr)'],
      'B. Skiing (Ski + Moving + Ski Clothes / lSki + Moving + Lift + Ski Clothes) (available for those ages 7 and above)':['Ignore'],
      'B. Skiing (Ski + Moving Walk/Lift + Ski Clothes & Pants) (available for those ages 7 and above)': ['Ignore'],
      'C. Snowboard (Snowboard + Moving Walk/Lift + Ski Clothes & Pants) (available for those ages 7 and above)': ['Ignore'],
      'None': ['Ignore']
    },
    '엘리시안': {
      'A. Suhttle to and from the Ski Resort (for those aged 3 and above)': ['A : Shuttle (Transport Only)'],
      'B-1: Ski Only': ['B : Ski Only'],
      'B-2:Ski + Lift Pass (8 hours) + Moving Walk Pass': ['C : Ski + Lift(8Hrs) + Moving Walk Pass'],
      'C-1: Snowboard only': ['D : Snowboard Only'],
      'C-2: Snowboard + Lift Pass (8 hours) + Moving Walk Pass': ['E : Snowboard + Lift(8Hrs)+ Moving Walk Pass'],
      'D-1: Sightseeing (one round trip lift)': ['F : Sightseeing (1 round-trip lift)'],
      'D-2: Sled (3 hours)': ['G : Sled(3Hrs)'],
      'D-3: Sightseeing (one round trip lift) + sledding (3 hours)': ['H : Sightseeing(1 round-trip lift) + Sled(3Hrs)'],
      'Basic group lesson + Ski Clothes Rental': ['X: Basic Group Lesson(1Hr)', 'Y: Ski Clothes Rental'],
      'Basic group lesson': ['X: Basic Group Lesson(1Hr)'],
      'Ski Clothes Rental': ['Y: Ski Clothes Rental'],
      'Basic Group Lesson + Ski Clothes Rental': ['X: Basic Group Lesson(1Hr)', 'Y: Ski Clothes Rental'],
      'B. Ski (Ski Only, or Ski + Lift + Moving Walk) for those ages 7 and above)': ['Ignore'],
      'C. Snowboard (Snowboard Only, or Snowboard + Lift + Moving Walk)(for those aged 7 and above)': ['Ignore'],
      'D. Non-skiing (sightseeing/sledding) (for those ages 3 and above)': ['Ignore'],
      'None': ['Ignore']
    },
    '남이엘리시안': {
      'A. Suhttle to and from the Ski Resort (for those aged 3 and above)': ['A : Shuttle (Transport Only)'],
      'B-1: Ski Only': ['B : Ski Only'],
      'B-2: Ski + Lift + Moving Walk': ['C : Ski + Lift + Moving Walk Pass'],
      'C-1: Snowboard only': ['D : Snowboard Only'],
      'C-2: Snowboard + Lift + Moving Walk': ['E : Snowboard + Lift + Moving Walk Pass'],
      'D-1: Sightseeing (one round trip lift)': ['F : Sightseeing (1 round-trip lift)'],
      'D-2: Sled (3 hours)': ['G : Sled(3Hrs)'],
      'D-3: Sightseeing (one round trip lift) + sled (3 hours)': ['H : Sightseeing(1 round-trip lift) + Sled(3Hrs)'],
      'Basic group lesson + Ski Clothes Rental': ['X: Basic Group Lesson(1Hr)', 'Y: Ski Clothes Rental'],
      'Basic group lesson': ['X: Basic Group Lesson(1Hr)'],
      'Ski Clothes Rental': ['Y: Ski Clothes Rental'],
      'Ski clothing rental': ['Y: Ski Clothes Rental'],
      'B. Ski (Ski Only, or Ski + Lift + Moving Walk) (available for those ages 7 and above)': ['Ignore'],
      'C. Snowboard (Snowboard Only, or Snowboard + ski lift + Moving Walk) (for those aged 7 and above)': ['Ignore'],
      'D. Non-ski (sightseeing/sledding) (for those ages 3 and above)': ['Ignore'],
      'None': ['Ignore']
    },

    '어비비발디': {
      'A. Transportation shuttle to and from the ski resort (over 3 years old)': ['A: Shuttle (Transport Only)'],
      'B-1: Double Ski + Escalator Ticket (Above 7 years old)': ['B: Ski + Moving Walk Pass(7Hrs)'],
      'B-2: Double Ski + Snow Lift Ticket (Age 7 and above)': [' C: Ski + Lift Pass(7Hrs)'],
      'C-1: Single Ski + Escalator Ticket (Age 7+)': ['D: Snowboard + Moving Walk Pass(7Hrs)'],
      'C-2: Snowboarding + Snow Lift Ticket (Age 7+)': ['E: Snowboard + Lift Pass(7Hrs)'],
      'D-1: Sightseeing (1 round-trip gondola) (from 12/20) (over 3 years old)': ['F: Sightseeing(1 round-trip gondola)'],
      'D-2: Sled (from 12/20) (over 3 years old)': ['G: Sled(Snowyland 1 Round-Trip Gondola)'],
      'Basic group Lesson': ['X: Basic Group Lesson(1Hr)'],
      'Ski clothing rental': ['Y: Ski Clothes Rental'],
      'Basic group lessons + ski clothing rental': ['X: Basic Group Lesson(1Hr)', 'Y: Ski Clothes Rental'],
      'None': ['Ignore']
    },

    '비주스': {
      'A. Transportation shuttle to and from the ski resort (over 3 years old)': ['A: Shuttle (Transport Only)'],
      'B-1: Double Ski + Escalator Ticket (Above 7 years old)': ['B: Ski + Moving Walk Pass(7Hrs)'],
      'B-2: Double Ski + Snow Lift Ticket (Age 7 and above)': [' C: Ski + Lift Pass(7Hrs)'],
      'C-1: Single Ski + Escalator Ticket (Age 7+)': ['D: Snowboard + Moving Walk Pass(7Hrs)'],
      'C-2: Snowboarding + Snow Lift Ticket (Age 7+)': ['E: Snowboard + Lift Pass(7Hrs)'],
      'D-1: Sightseeing (1 round-trip gondola) (from 12/20) (over 3 years old)': ['F: Sightseeing(1 round-trip gondola)'],
      'D-2: Sled (from 12/20) (over 3 years old)': ['G: Sled(Snowyland 1 Round-Trip Gondola)'],
      'Basic group Lesson': ['X: Basic Group Lesson(1Hr)'],
      'Ski clothing rental': ['Y: Ski Clothes Rental'],
      'Basic group lessons + ski clothing rental': ['X: Basic Group Lesson(1Hr)', 'Y: Ski Clothes Rental'],
      'None': ['Ignore']
    },

    '남이비발디': {
      'A: Transportation shuttle bus to and from the ski resort (over 3 years old)': ['A: Shuttle (Transport Only)'],
      'B-1: Double Ski + Escalator Ticket (Above 7 years old)': ['B: Ski + Moving Walk Pass(7Hrs)'],
      'B-2: Double Ski + Snow Lift Ticket (Age 7 and above)': ['C: Ski + Lift Pass(7Hrs)'],
      'C-1: Single Ski + Escalator Ticket (Age 7+)': ['D: Snowboard + Moving Walk Pass(7Hrs)'],
      'C-2: Snowboarding + Snow Lift Ticket (Age 7+)': ['E: Snowboard + Lift Pass(7Hrs)'],
      'D-1: Sightseeing (1 round-trip gondola) (from 12/20) (over 3 years old)': ['F: Sightseeing(1 round-trip gondola)'],
      'D-2: Sled (from 12/20) (over 3 years old)': ['G: Sled(Snowyland 1 Round-Trip Gondola)'],
      'Basic group Lesson': ['X: Basic Group Lesson(1Hr)'],
      'Ski clothing rental': ['Y: Ski Clothes Rental'],
      'Basic group lessons + ski clothing rental': ['X: Basic Group Lesson(1Hr)', 'Y: Ski Clothes Rental'],
      'Hongcheon Daemyung Vivaldi Park Ski World + Nami Island': ['Ignore'],
      'None': ['Ignore']
    }
  },

  'TPC': {
    "엘리시안": {
      "A: 滑雪场往返接送": ["A : Shuttle (Transport Only)"],
      "B: 双板滑雪 + 基础教学(1小时) + 滑雪服": ["B : Ski Only", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "C: 双板滑雪 + 基础教学(1小时) + 滑雪服 +缆车票 +電動扶梯": ["C : Ski + Lift(8Hrs) + Moving Walk Pass", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "D: 单板滑雪 + 基础教学(1小时) + 滑雪服": ["D : Snowboard Only", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "E: 单板滑雪 + 基础教学(1小时) + 滑雪服 +缆车票 +電動扶梯": ["E : Snowboard + Lift(8Hrs)+ Moving Walk Pass", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "F: 纯观光 (含观光缆车票)": ["F : Sightseeing (1 round-trip lift)"],
      "F: 纯观光(含观光缆车票)": ["F : Sightseeing (1 round-trip lift)"],
      "G: 雪橇(3小时)": ["G : Sled(3Hrs)"],
      "H: 观光 (含观光缆车票) + 雪橇(3小时)": ["H : Sightseeing(1 round-trip lift) + Sled(3Hrs)"],
      "H: 观光(含观光缆车票) + 雪橇(3小时)": ["H : Sightseeing(1 round-trip lift) + Sled(3Hrs)"]
    },
    "남이엘리시안": {
      "A: 滑雪场往返接送": ["A : Shuttle (Transport Only)"],
      "B: 双板滑雪 + 基础教学(1小时) + 滑雪服": ["B : Ski Only", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "C: 双板滑雪 + 基础教学(1小时) + 滑雪服 +缆车票 +電動扶梯": ["C : Ski + Lift + Moving Walk Pass", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "D: 单板滑雪 + 基础教学(1小时) + 滑雪服": ["D : Snowboard Only", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "E: 单板滑雪 + 基础教学(1小时) + 滑雪服 +缆车票 +電動扶梯": ["E : Snowboard + Lift + Moving Walk Pass", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "F: 纯观光 (含观光缆车票)": ["F : Sightseeing (1 round-trip lift)"],
      "F: 纯观光(含观光缆车票)": ["F : Sightseeing (1 round-trip lift)"],
      "G: 雪橇(3小时)": ["G : Sled(3Hrs)"],
      "G: 雪橇 (3小时)": ["G : Sled(3Hrs)"],
      "H: 观光 (含观光缆车票) + 雪橇(3小时)": ["H : Sightseeing(1 round-trip lift) + Sled(3Hrs)"],
      "H: 观光(含观光缆车票) + 雪橇(3小时)": ["H : Sightseeing(1 round-trip lift) + Sled(3Hrs)"]
    },
    "어비비발디": {
      "A: 滑雪场往返接送": ["A: Shuttle (Transport Only)"],
      "B: 双板滑雪 + 基础教学(1小时) + 滑雪服+電動扶梯": ["B: Ski + Moving Walk Pass(7Hrs)", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "C: 双板滑雪 + 基础教学(1小时) + 滑雪服 +缆车票": ["C: Ski + Lift Pass(7Hrs)", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "D: 单板滑雪 + 基础教学(1小时) + 滑雪服 +電動扶梯": ["D: Snowboard + Moving Walk Pass(7Hrs)", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "E: 单板滑雪 + 基础教学(1小时) + 滑雪服 +缆车票": ["E: Snowboard + Lift Pass(7Hrs)", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "F: 纯观光 (含观光缆车票一次往返)": ["F: Sightseeing(1 round-trip gondola)"],
      "F: 纯观光(含观光缆车票一次往返)": ["F: Sightseeing(1 round-trip gondola)"],
      "G: 冰雪王国雪橇": ["G: Sled(Snowyland 1 Round-Trip Gondola)"]
    },
    "남이비발디": {
      "A: 滑雪场往返接送": ["A: Shuttle (Transport Only)"],
      "B: 双板滑雪 + 基础教学(1小时)+ 滑雪服+電動扶梯": ["B: Ski + Moving Walk Pass(7Hrs)", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "C: 双板滑雪 + 基础教学(1小时) + 滑雪服 +缆车票": ["C: Ski + Lift Pass(7Hrs)", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "D: 单板滑雪 + 基础教学(1小时) + 滑雪服 +電動扶梯": ["D: Snowboard + Moving Walk Pass(7Hrs)", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "E: 单板滑雪 + 基础教学(1小时) + 滑雪服 +缆车票": ["E: Snowboard + Lift Pass(7Hrs)", "X: Basic Group Lesson(1Hr)", "Y: Ski Clothes Rental"],
      "F: 纯观光 (含观光缆车票)": ["F: Sightseeing(1 round-trip gondola)"],
      "F: 纯观光(含观光缆车票)": ["F: Sightseeing(1 round-trip gondola)"],
      "G: 冰雪王国雪橇": ["G: Sled(Snowyland 1 Round-Trip Gondola)"]
    },
    "지산": {
      "A: 滑雪场往返接送": ["A : Shuttle (Transport Only)"],
      "B: 双板滑雪 + 基础教学(1小时) + 滑雪服+電動扶梯": ["B : Ski + Moving Walk Pass + Ski Clothes(jacket, pants)", "G: Basic Group Lesson(1Hr)"],
      "C: 双板滑雪 + 基础教学(1小时) + 滑雪服 +缆车票 +電動扶梯": ["C : Ski + Moving Walk Pass + Lift Pass + Ski Clothes(jacket, pants)", "G: Basic Group Lesson(1Hr)"],
      "D: 单板滑雪 + 基础教学(1小时) + 滑雪服 + 電動扶梯": ["D : Snowboard + Moving Walk Pass + Ski Clothes(jacket, pants)", "G: Basic Group Lesson(1Hr)"],
      "E: 单板滑雪 + 基础教学(1小时) + 滑雪服 +缆车票 +電動扶梯": ["E : Snowboard + Moving Walk Pass + Lift Pass + Ski Clothes(jacket, pants)", "G: Basic Group Lesson(1Hr)"],
      "F: 雪橇": ["F : Sled"]
    },
    "에덴": {
      "A: 滑雪场往返接送": ["A : Shuttle (Transport Only)"],
      "B: 双板滑雪 + 基础教学(1小时) + 滑雪服 + 缆车搭乘一次": ["B : Ski + Lift(1 time) + Ski Clothes(jacket, pants)", "Basic Group Lesson(1Hr)"],
      "C: 单板滑雪 + 基础教学(1小时) + 滑雪服 + 缆车搭乘一次": ["C : Snowboard + Lift(1 time) + Ski Clothes(jacket, pants)", "Basic Group Lesson(1Hr)"],
      "C: 单板滑雪 + 基础教学(1小时)  + 滑雪服 + 缆车搭乘一次": ["C : Snowboard + Lift(1 time) + Ski Clothes(jacket, pants)", "Basic Group Lesson(1Hr)"],
      "D: 纯观光 + 滑雪服": ["D : Sightseeing Only"],
      "E: 雪橇": ["E : Sled"],
      "F: 双板滑雪 + 基础教学(1小时) + 滑雪服 + 雪地缆车券(5小时)": ["F : Ski + Lift Pass (5-Hour) + Ski Clothes (Jacket, pants)", "Basic Group Lesson(1Hr)"],
      "G: 单板滑雪 + 基础教学(1小时) + 滑雪服 + 雪地缆车券(5小时)": ["G : Snowboard + Lift Pass (5-Hour) + Ski Clothes (Jacket, pants)", "Basic Group Lesson(1Hr)"],
      "追加 SPA LAND 汗蒸幕入场券": ["SPA LAND"]
    }
  }
}

export function findSkiOptions(agency: string, possibleProduct: string, possibleOption: string, packageName?:string,): string[] | undefined {
  switch (agency) {
    case 'VI':
      return handleViatorSki(possibleProduct, possibleOption);
    case 'KK': {
      const productFound = Object.entries(SKI_PRODUCTS).find(([possible, product]) => possibleProduct?.toLowerCase().trim() === possible?.toLowerCase().trim())
      ?? Object.entries(SKI_PRODUCTS).find(([possible]) => packageName?.toLowerCase().trim() === possible.toLowerCase().trim());
      const product = productFound?.[1];
      if (!product) return undefined;
      const skiOptions = SKI_OPTIONS[agency]?.[product];
      if (!skiOptions) return undefined;
      const found = Object.entries(skiOptions).find(([optionStatement]) => possibleOption?.toLowerCase().trim() === optionStatement?.toLowerCase().trim())?.[1];
      return found;
    }
    case 'TPC': {
      const productFound = Object.entries(SKI_PRODUCTS).find(([possible, product]) =>
        possibleProduct?.toLowerCase().trim().includes(possible?.toLowerCase().trim())
        || packageName?.toLowerCase().trim().includes(possible?.toLowerCase().trim())
      );
      const product = productFound?.[1];
      if (!product) return undefined;
      const skiOptions = SKI_OPTIONS[agency]?.[product];
      if (!skiOptions) return undefined;
      return Object.entries(skiOptions).find(([optionStatement]) => possibleOption?.toLowerCase().trim().includes(optionStatement?.toLowerCase().trim()))?.[1];
    }
    default:
      return undefined;
  }
}

function handleViatorSki(possibleProduct: string, possibleOption: string): string[] | undefined {
  if (possibleProduct.toLowerCase()?.includes('elysian') || possibleProduct?.toLowerCase().includes('vivaldi')) {
    if (possibleOption.toUpperCase().includes('SHUTTLE ONLY')) {
      return ['A : Shuttle (Transport Only)']
    }
    if (possibleOption.toUpperCase().includes('SIGHTSEEING')) {
      return ['F : Sightseeing (1 round-trip lift)', 'Y: Ski Clothes Rental'];
    }
    if (possibleOption.toUpperCase().includes('SLED')) {
      return ['G : Sled(3Hrs)', 'Y: Ski Clothes Rental'];
    }
    if (possibleOption.toUpperCase().includes('SNOWBOARD')) {
      return ['E : Snowboard + Lift(8Hrs)+ Moving Walk Pass', 'X: Basic Group Lesson(1Hr)', 'Y: Ski Clothes Rental'];
    }
    if (possibleOption.toUpperCase().includes('SKI')) {
      return ['C : Ski + Lift(8Hrs) + Moving Walk Pass', 'X: Basic Group Lesson(1Hr)', 'Y: Ski Clothes Rental'];
    }
  } else if (possibleProduct?.toLowerCase().includes('jisan')) {
    if (possibleOption.toUpperCase().includes('SHUTTLE ONLY')) {
      return ['A : Shuttle (Transport Only)'];
    }

    if (possibleOption.toUpperCase().includes('SKI')) {
      return ['C : Ski + Moving Walk Pass + Lift Pass + Ski Clothes(jacket, pants)', 'G: Basic Group Lesson(1Hr)'];
    }

    if (possibleOption.toUpperCase().includes('SNOWBOARD')) {
      return ['E : Snowboard + Moving Walk Pass + Lift Pass + Ski Clothes(jacket, pants)', 'G: Basic Group Lesson(1Hr)'];
    }

    if (possibleOption.toUpperCase().includes('SLED')) {
      return ['F : Sled'];
    }

  } else if (possibleProduct?.toLowerCase().includes('eden')) {
    if (possibleOption.toUpperCase().includes('SHUTTLE ONLY')) {
      return ['A : Shuttle (Transport Only)'];
    }

    if (possibleOption.toUpperCase().includes('SKI')) {
      return ['B : Ski + Lift(1 time) + Ski Clothes(jacket, pants)', 'Basic Group Lesson(1Hr)'];
    }

    if (possibleOption.toUpperCase().includes('SNOWBOARD')) {
      return ['C : Snowboard + Lift(1 time) + Ski Clothes(jacket, pants)', 'Basic Group Lesson(1Hr)'];
    }

    if (possibleOption.toUpperCase().includes('SIGHTSEEING')) {
      return ['D : Sightseeing Only'];
    }
    if (possibleOption.toUpperCase().includes('SLED')) {
      return ['E : Sled'];
    }
  }
  return undefined;
}


import React, {useEffect, useMemo, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import {DateRange, DateRangePicker, SingleInputDateRangeField} from "@mui/x-date-pickers-pro";

import {useRead, useReadPropValueStartEndAt} from "../../../hooks/realtime";
import {readWithKeyStartEndAt} from "../../../hooks/firebase";
import {Operation} from "../../../models/Operation";
import {Product} from "../../../models/Product";
import {User} from "../../../models/User";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import Typography from '@mui/material/Typography';

type Products = { [productId: string]: Product };
type Users = { [userId: string]: User };

type GuideOperationEntity = {
  guideId: string,
  guideName: string,
  date: string,
  product: string,
  people: number,
  adult: number,
  kid: number,
  count: number | string,
  pickup: string,
  pay: number | string,
  incentive: number | string,
}

type GuideOperationRow = Partial<GuideOperationEntity> & {
  id: string,
  day: string,
  guideId: string,
  guideName: string,
  groupPath: string[]
  cardExpenditure: string | number,
  cardIncome: string | number,
  cashExpenditure: string | number,
  cashIncome: string | number,
  memo?: string
}

type Count = { count: number, people: number, adult: number, kid: number, infant: number };

type GuideTourEntity = {
  guideId: string,
  guideName: string,
  date: string, product: string, present: Count, noShow: Count, total: Count, pickupSet: Set<string>,
  productId: string
  type: 'exploration' | 'apprenticeship' | 'education' | 'driver' | 'tour'
};


function DateRangePickerValue(props: { dateRange: DateRange<Dayjs>, onChange: (dr: DateRange<Dayjs>) => void }) {
  const {dateRange: value, onChange: setValue} = props;
  return (

    <DateRangePicker
      format={'YY-MM-DD'}
      slots={{field: SingleInputDateRangeField}}
      value={value}
      onChange={(newValue) => setValue(newValue)}
    />
  );
}


export default function () {
  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>(getLastWeekDateRange());
  const [tempDateRange, setTempDateRange] = React.useState<DateRange<Dayjs>>(dateRange);
  const formatDateRanges = useMemo(() => dateRange.map(djs => (djs ?? dayjs()).format('YYYY-MM-DD')), [dateRange]);
  const dates = useMemo(() => {
    const endDayjs = dateRange[1] ?? dayjs();
    const startDayjs = dateRange[0] ?? dayjs();
    const dateRangeLength = endDayjs.diff(startDayjs, 'day') + 1;
    return (new Array(dateRangeLength))
      .fill(startDayjs)
      .map((startDate, idx) => dayjs(startDate).add(idx, 'day'));
  }, [dateRange]);
  const dateHeads = useMemo(() => dates.map((d) => d.format('YYYY-MM-DD')), dates);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadKey, setLoadKey] = useState<string>(Date.now() + '');
  const [operations, setOperations] = useState<{ [formattedDate: string]: Operation }>({});
  const {data: products,} = useRead<Products>('/product');
  const {data: users,} = useRead<Users>('/user');


  const tourGuideReducedReservationTuples = useMemo(() =>
    Object.entries(operations)
      .map(([date, {tours}]) => {
        const tourList = Object.values(tours ?? {});
        return tourList.map((tour) => {
          const product = products?.[tour.productId]?.name ?? tour.product;
          const teams = Object.values(tour.teams ?? {})
          return teams.map<[string, GuideTourEntity][]>((team) => {
            const guides = team.guides
            const explorationGuideIds = (team.explorations ?? []).map(({id}) => id);
            const apprenticeshipGuideIds = (team.apprenticeships ?? []).map(({id}) => id);
            const educationGuideIds = (team.educations ?? []).map(({id}) => id);
            const driverGuideIds = (team.drivers ?? []).map(({id}) => id);

            const reducedReservations = Object.values(team.reservations ?? {}).reduce((result, reservation) => {
                const adult = reservation.adult;
                const kid = reservation.kid;
                const infant = reservation.infant;
                const people = adult + kid + infant;
                if (reservation.noShow) {
                  result.noShow.adult += adult;
                  result.noShow.kid += kid;
                  result.noShow.infant += infant;
                  result.noShow.people += people;
                  result.noShow.count += 1;
                } else {
                  result.present.adult += adult;
                  result.present.kid += kid;
                  result.present.infant += infant;
                  result.present.people += people;
                  result.present.count += 1;
                }
                result.total.adult += adult;
                result.total.kid += kid;
                result.total.infant += infant;
                result.total.people += people;
                result.total.count += 1;
                result.pickupSet.add(reservation.pickupPlace);
                return result;
              }, ({
                date,
                product,
                present: {count: 0, people: 0, adult: 0, kid: 0, infant: 0},
                noShow: {count: 0, people: 0, adult: 0, kid: 0, infant: 0},
                total: {count: 0, people: 0, adult: 0, kid: 0, infant: 0},
                pickupSet: new Set<string>(),
                productId: tour.productId,
              } as GuideTourEntity)
            )
            return guides?.map(g => [g.id, {
              ...reducedReservations,
              guideName: g.name,
              type: explorationGuideIds.includes(g.id) ? 'exploration' : apprenticeshipGuideIds.includes(g.id) ? 'apprenticeship' : educationGuideIds.includes(g.id) ? 'education' : driverGuideIds.includes(g.id) ? 'driver' : 'tour'
            }]) ?? []
          }).flat(1);
        }).flat(1)
      }).flat(1), [operations]);

  const reducedTourGuideTupleMap = useMemo(() => tourGuideReducedReservationTuples.reduce((result, [guideId, entity]) => {
    if (!result[guideId]) result[guideId] = [];
    result[guideId].push(entity);
    return result;
  }, ({} as { [guideId: string]: GuideTourEntity[] })), [tourGuideReducedReservationTuples]);

  const guideReservationCounts = useMemo(() => {
    return Object.entries(reducedTourGuideTupleMap).map(([guideId, records]) => {
      const guide = users?.[guideId];
      const guideName = guide ? `${guide?.name}(${guide.nameEn})` : guideId;
      const recordDateMap = Object.fromEntries(records.map((record) => [record.date, record]));
      return [guideName, ...([...dateHeads].map((date) => recordDateMap[date] ?? ''))];
    })
  }, [users, reducedTourGuideTupleMap, dateHeads]);


  useEffect(() => {
    setLoading(true);
    readWithKeyStartEndAt<Operation>('/operation', formatDateRanges[0], formatDateRanges[1])
      .then((operations) => {
        setOperations(operations);
      })
      .finally(() => {
        setLoading(false);
      });
  }, formatDateRanges)

  const handleTempDateRange = (dr: DateRange<Dayjs>) => {
    setTempDateRange(dr);
  }
  const handleConfirmDateRange = () => {
    setDateRange(tempDateRange);
  }

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Box p={1}>
            <Card>
              <CardContent sx={(theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                color: theme.palette.text.primary,
                padding: '8px !important'
              })}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}>
                  <DateRangePickerValue onChange={handleTempDateRange} dateRange={dateRange}/>
                  {
                    loading ?
                      <CircularProgress/>
                      : <Button
                        sx={{height: '100%'}}
                        onClick={handleConfirmDateRange}
                        variant={'contained'}
                        disabled={dateRange.map(d => d?.format('YYYY-MM-DD')).join() === tempDateRange.map(d => d?.format('YYYY-MM-DD')).join()}>
                        Confirm
                      </Button>

                  }
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Card>
              <CardContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Guide
                      </TableCell>
                      {
                        dateHeads.map(dh =>
                          <TableCell key={dh} align={'center'}>
                            {dh}
                          </TableCell>
                        )
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      guideReservationCounts
                        .map((gc) => {
                          const [guideName, ...records] = gc as [string, ...GuideTourEntity[]];
                          return (
                            <TableRow key={guideName ?? ''}>
                              <TableCell>
                                {guideName}
                              </TableCell>
                              {records.map(record => (
                                <TableCell key={record.date}>
                                  {
                                    record &&
                                      <Box sx={{
                                        display: 'inline-flex',
                                        flexDirection: 'column',
                                        gap: '4px',
                                        alignItems: 'center',
                                        width: '100%'
                                      }}>
                                        <Typography>
                                          {record.product}
                                          {
                                            record.type !== 'tour'
                                            && (
                                              <Typography variant={'caption'} color={
                                                record.type === 'exploration' ? 'red'
                                                  : record.type === 'apprenticeship' ? 'blue'
                                                    : record.type === 'education' ? 'green'
                                                      : record.type === 'driver' ? 'purple'
                                                        : ''
                                              }>
                                                ({record.type === 'exploration' ? '답사'
                                                : record.type === 'apprenticeship' ? '수습'
                                                  : record.type === 'education' ? '교육'
                                                    : record.type === 'driver' ? '운전'
                                                      : ''})
                                              </Typography>
                                            )
                                          }
                                        </Typography>
                                        <Typography>
                                          {record.total.count}
                                        </Typography>
                                      </Box>
                                  }
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })
                    }
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

function getLastWeekDateRange(): [Dayjs, Dayjs] {
  const today = new Date();
  const lastWeekDate = new Date(today);
  lastWeekDate.setDate(lastWeekDate.getDate() - 7);

  const dayOfWeek = lastWeekDate.getDay(); // 일요일: 0, 월요일: 1, ..., 토요일: 6
  const monday = new Date(lastWeekDate);
  monday.setDate(lastWeekDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));
  const sunday = new Date(lastWeekDate);
  sunday.setDate(lastWeekDate.getDate() + (dayOfWeek === 0 ? 0 : 7 - dayOfWeek));
  return [dayjs(monday), dayjs(sunday)];
}

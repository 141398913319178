import * as XLSX from "xlsx";
import product from "../../settings/product";
import {KintPriceDatum} from "./types";

function cleanString(data?: any): string {
  return data?.toString().trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0) || 0;
}

const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array"})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames.slice(0, 3);
  return sheetNames.map((sheetName) => XLSX.utils.sheet_to_json(sheets[sheetName]) as any[]).flat(1)
}

export async function parseKintPriceFile(file: Blob | File): Promise<KintPriceDatum[]> {
  const parsed = await parseFileData(file);
  const results = parsed.reduce((result, row) => {
    const product = row['상품']
    const klookAdult = toNumber(row['Klook Adult']);
    const klookKid = toNumber(row['Klook Kid']);
    const ctripAdult = toNumber(row['Trip.com Adult(USD))']);
    const ctripKid = toNumber(row['Trip.com Kid(USD)']);
    const othersAdult = toNumber(row['기타 Adult']);
    const othersKid = toNumber(row['기타 Kid']);
    const viatorAdult = toNumber(row['viator Adult']);
    const viatorKid = toNumber(row['viator Kid']);
    const otherUSDAdult = toNumber(row['USD Adult']);
    const otherUSDKid = toNumber(row['USD Kid']);
    const direct = toNumber(row['직접']);

    result[product] = {
      product: row['상품'],
      direct: direct,
      guide:0,
      driver:0,
      klook: {adult: klookAdult, kid: klookKid},
      ctrip: {adult: ctripAdult, kid: ctripKid},
      others: {adult: othersAdult, kid: othersKid},
      viator: {adult: viatorAdult, kid: viatorKid},
      othersUSD: {adult: otherUSDAdult, kid: otherUSDKid},
    }
    return result;
  }, {} as { [productName: string]: KintPriceDatum });

  return Object.values(results);
}

import React, {useEffect, useState, useContext, useCallback} from "react"

import dayjs, {Dayjs} from "dayjs"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import ButtonBase from "@mui/material/ButtonBase"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"

import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Modal from "@mui/material/Modal"

import Icon from "@mui/material/Icon"

import {DatePicker} from "@mui/x-date-pickers"

import {DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core"
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy, // <== doesn't break if this is rectSortingStrategy
} from "@dnd-kit/sortable"

import {useSortable} from "@dnd-kit/sortable"
import {CSS} from "@dnd-kit/utilities"

import {CardActionArea, Stack} from "@mui/material"
import {DragEndEvent} from "@dnd-kit/core/dist/types"

import {Operation, Tour, Team, Notification} from "../../models/Operation"
import {useListen, useRead} from "../../hooks/realtime"
import {callFunction, getCurrentUser, logAction, removeRealtime, updateRealtime} from "../../hooks/firebase"

import TeamButton from "../../components/TeamButton"
import TourModal from "../../partials/TourModal"
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import PeopleIcon from "@mui/icons-material/PeopleOutline"
import TeamIcon from "@mui/icons-material/TourOutlined"

import DateTabs from "../../components/DateTabs"
import {AuthContext, useAuth} from "../../hooks/auth"
import {DataGridPro, GridColDef, GridToolbar, GridToolbarContainer, useGridApiContext} from "@mui/x-data-grid-pro"

import writeXlsxFile from "write-excel-file"
import {Product} from "../../models/Product"
import {createSearchContext} from "../../hooks/search"
import {useQueryDocs, useQueryListen} from "../../hooks/firestore";
import {Reservation, ReservationBase} from "../../models/Reservation";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import NewNewBus from "./NewNewBus";
import {User} from "../../models/User";
import Railbike from "./Railbike";

const {SearchContext, useSearch, SearchProvider} = createSearchContext()

type UniqueTour = Tour & { id: string }

const OPERATOR = Object.freeze([
  {
    id: "SX1njRz4BbevBd6Exb9Z07U7F5D2",
    name: "박호영",
    nameEn: "Dylan",
    type: "Operator",
  },
  {
    id: "Spt1OZhXCvMLKmjGpFLQldoTQk22",
    name: "이영훈",
    nameEn: "Younghun",
    type: "Operator",
  },
  {
    id: "Vermu2kOJeNZiqEm6JBPd8yul6N2",
    name: "박영규",
    nameEn: "Rupert",
    type: "Operator",
  },
  {
    id: "hut7yktlwbbBPTUrKZo2zlsT3YQ2",
    name: "정혜민",
    nameEn: "Christine",
    type: "Operator",
  },
  {
    id: "WMVO4cwqr2gCICd0EDmwJyrczp13",
    name: "최단",
    nameEn: "Dan",
    type: "Operator",
  },
  {
    id: "BmVRwOul3KNJplS0Fz6hGXMgiwm2",
    name: "조한진",
    nameEn: "Josh",
    type: "Operator",
  },
  {
    id: "2EM3P99KLJdXbplsdxr6pCmAxXy1",
    name: "권윤희",
    nameEn: "Jennifer",
    type: "Operator",
  },
  {
    id: "o6qwrWYEyOTrX7AyPrcVK5f8oc72",
    name: "Ktourstory",
    nameEn: "Ktourstory",
    type: "Operator",
  },
])

const ACCOUNTANTS = Object.freeze([
  {
    id: "wSe5st6Bj8UGvvXl3SBS1liY9RV2",
    name: "김서희",
    nameEn: "Eliana",
    type: "Operator",
  },
  {
    id: "rZhkdyxyFHXjpttuxNzAdNrAgY23",
    name: "김주혜",
    nameEn: "Juhae",
    type: "Operator",
  },
])

function DndTourCard(props: {
  onClick: (tour: UniqueTour) => void
  onClickTeam: (team: Team) => void
  onDeleteTeam: (team: Team, tour: Tour) => void
  tour: UniqueTour
  date: string
  width: string | number
  minWidth: string | number
  maxWidth: string | number
}) {
  const {tour, date} = props
  const {keyword} = useSearch()
  const {attributes, isDragging, listeners, setNodeRef, transform, transition} = useSortable(tour)
  const {product, productId, teams = {}} = props.tour
  const teamList = Object.values(teams)
    .map((team, idx) => {
      team.idx = (idx + 1)
      return team
    })
    .sort(({idx: a}, {idx: b}) => a - b)
    .map((team) => {
      if (
        keyword &&
        Object.values(team.reservations ?? {}).filter((r) =>
          `${r.clientName}${r.agencyCode}${r.email}${r.tel}${r.messenger}${r.id}${r.memo}`
            .toLowerCase()
            .includes(keyword.toLowerCase())
        ).length
      ) {
        team.highlight = true
      } else {
        team.highlight = false
      }
      return team
    })
  const guides = teamList
    .map((t) => t.guides)
    .flat(1)
    .filter((g) => !!g)

  const people = teamList.reduce(
    (result, team) =>
      result +
      Object.values(team.reservations ?? {}).reduce(
        (people, reservation) => people + (reservation.adult + reservation.kid + reservation.infant),
        0
      ),
    0
  );

  const adult = teamList.reduce(
    (result, team) =>
      result +
      Object.values(team.reservations ?? {}).reduce(
        (people, reservation) => people + (reservation.adult),
        0
      ),
    0
  );

  const kid = teamList.reduce(
    (result, team) =>
      result +
      Object.values(team.reservations ?? {}).reduce(
        (people, reservation) => people + (reservation.kid),
        0
      ),
    0
  );

  const infant = teamList.reduce(
    (result, team) =>
      result +
      Object.values(team.reservations ?? {}).reduce(
        (people, reservation) => people + (reservation.infant),
        0
      ),
    0
  )

  const {auth: currentUser} = useContext(AuthContext) as any
  const [loadingCreateChat, setLoadingCreateChat] = useState(false)
  const [chatId, setChatId] = useState<string | null>(null)
  const handleClick = () => {
    props.onClick(props.tour)
  }

  const handleClickTeam = (team: Team) => {
    props.onClickTeam(team)
    props.onClick(props.tour)
  }

  const handleDeleteTeam = (team: Team) => {
    props.onDeleteTeam(team, props.tour)
  }

  useEffect(() => {
  }, [guides.map((g) => g!.id).join("_")])

  return (
    <Card
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      onClick={handleClick}
      sx={(theme) => ({
        minWidth: props.minWidth,
        maxWidth: props.maxWidth,
        width: props.width,
        opacity: isDragging ? 0.7 : 1,
        transition: transition,
        transform: CSS.Transform.toString(transform),
      })}
    >
      <CardActionArea
        component={Box}
        sx={{
          height: "100%",
          py: 4,
          px: 3,
        }}
      >
        <CardHeader
          disableTypography
          title={
            <Typography variant={"h4"} textAlign={"center"} mb={3}>
              {product}
              {// 채팅 부르기
                /*{loadingCreateChat ? (*/}
              {/*    <CircularProgress size={28} sx={{width: "28px", height: "28px"}}/>*/}
              {/*) : (*/}
              {/*    <IconButton*/}
              {/*        onClick={async (e) => {*/}
              {/*            e.stopPropagation()*/}
              {/*            e.preventDefault()*/}

              {/*            if (!guides || guides.length === 0) return*/}
              {/*            if (window.confirm("채팅방을 여시겠습니까?")) {*/}
              {/*                async function recallChat() {*/}
              {/*                    setLoadingCreateChat(true)*/}
              {/*                    if (chatId) return chatId*/}
              {/*                    const chat = (await callFunction("recallChat", {*/}
              {/*                        category: "TOUR",*/}
              {/*                        cId: `${date}:${productId}`,*/}
              {/*                        title: `${date}:${product}`,*/}
              {/*                        participants: [*/}
              {/*                            ...guides.map((guide: any) => ({*/}
              {/*                                id: guide.id,*/}
              {/*                                name: guide.name,*/}
              {/*                                nameEn: guide.nameEn ?? "",*/}
              {/*                                type: "Guide",*/}
              {/*                            })),*/}
              {/*                            ...OPERATOR,*/}
              {/*                            ...(productId.toLowerCase().includes("ski") ? ACCOUNTANTS : []),*/}
              {/*                            {*/}
              {/*                                id: currentUser?.id,*/}
              {/*                                name: currentUser?.name,*/}
              {/*                                nameEn: currentUser?.nameEn ?? "",*/}
              {/*                                type: "Operator",*/}
              {/*                            },*/}
              {/*                        ],*/}
              {/*                        tour: {*/}
              {/*                            date: date,*/}
              {/*                            productId: productId,*/}
              {/*                            team: null,*/}
              {/*                        },*/}
              {/*                    })) as {*/}
              {/*                        id: any*/}
              {/*                        [key: string]: any*/}
              {/*                    }*/}
              {/*                    if (chat && chat.id) {*/}
              {/*                        setChatId(chat.id)*/}
              {/*                    }*/}
              {/*                    return chat.id*/}
              {/*                }*/}

              {/*                recallChat()*/}
              {/*                    .then((chatId) => {*/}
              {/*                        try {*/}
              {/*                            if (!chatId) return alert("적절한 채팅방을 찾을 수 없습니다.")*/}
              {/*                            const newWin = window.open(*/}
              {/*                                `/chat/rooms/${chatId}`,*/}
              {/*                                "_blank",*/}
              {/*                                "width=375,height=700"*/}
              {/*                            )*/}
              {/*                            newWin?.focus()*/}
              {/*                        } catch (e) {*/}
              {/*                            alert("채팅방을 열 수 없습니다.")*/}
              {/*                        }*/}
              {/*                    })*/}
              {/*                    .catch(console.error)*/}
              {/*                    .finally(() => {*/}
              {/*                        setLoadingCreateChat(false)*/}
              {/*                    })*/}
              {/*            }*/}
              {/*        }}*/}
              {/*    >*/}
              {/*        <ChatIcon/>*/}
              {/*    </IconButton>*/}
              {/*)}*/}
            </Typography>
          }
          subheader={
            <Box>
              <Stack justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"}>
                <Typography variant={"h6"} color={"text.secondary"}>
                  <Icon>
                    <PeopleIcon/>
                  </Icon>{" "}
                  People
                </Typography>
                <Typography variant={"h4"} color={"text.secondary"}>
                  {people}
                  <Typography variant={'body1'} component={'span'}>
                    ({adult}/{kid}/{infant})
                  </Typography>
                </Typography>
              </Stack>
              <Stack justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"}>
                <Typography variant={"h6"} color={"text.secondary"}>
                  <Icon>
                    {" "}
                    <TeamIcon/>
                  </Icon>{" "}
                  Team
                </Typography>
                <Typography variant={"h4"} color={"text.secondary"}>
                  {teamList.length}
                </Typography>
              </Stack>
              {/*<Stack justifyContent={'space-between'} flexDirection={'row'} alignItems={'center'}>*/}
              {/*    <Typography variant={'h6'} color={'text.secondary'}>*/}
              {/*        <Icon> <ReservationIcon/></Icon>{' '}Reservation*/}
              {/*    </Typography>*/}
              {/*    <Typography variant={'h4'} color={'text.secondary'}>*/}
              {/*        {teamList.map((team) => Object.values(team.reservations ?? {}).length).reduce((a, b) => a + b)}*/}
              {/*    </Typography>*/}
              {/*</Stack>*/}
            </Box>
          }
        />
        <CardContent component={Stack} spacing={2}>
          {teamList.map((team, idx) => (
            <TeamButton
              key={team.id}
              date={date}
              tour={tour}
              team={team}
              idx={idx}
              onClick={handleClickTeam}
              onDelete={handleDeleteTeam}
              highlight={team.highlight}
              hideReservation
            />
          ))}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

function DnDTours(props: {
  title: string
  date: string
  tours: UniqueTour[]
  notification?: Notification
  disableDnd?: boolean,
  onDnd: (tours: UniqueTour[]) => void
  onClick: (tour: UniqueTour) => void
  onClickTeam: (team: Team) => void
  onDeleteTeam: (team: Team, tour: Tour) => void
  onNotification: (title: string, body: string, targets: string[]) => void
  sendNotification: (title: string, body: string, targets: string[]) => void
}) {
  const {
    title,
    date,
    notification,
    onNotification,
    sendNotification,
    tours,
    disableDnd,
    onDnd,
    onClick,
    onClickTeam,
    onDeleteTeam,
  } = props
  const guides = tours.reduce((guides, tour) => {
    const _guides = Object.values(tour.teams ?? {})
      .map((team) => (team.guides ?? []).map((g) => g.id))
      .flat()
    return [...guides, ..._guides]
  }, [] as string[])
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor)
  )

  const handleTitle: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const title = e.target.value
    onNotification(title, notification?.body ?? "", guides)
  }

  const handleBody: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const body = e.target.value
    onNotification(notification?.title ?? "", body, guides)
  }

  const handleSend = () => {
    sendNotification(notification?.title ?? "", notification?.body ?? "", guides)
  }

  if (tours.filter(t => Object.values(t.teams ?? {}).length > 0).length === 0) return null;

  const cards = (
    <SortableContext items={tours}>
      <Stack flexDirection={"row"} gap={4} flexWrap={"wrap"}>
        {tours.map((tour) => (
          <DndTourCard
            key={tour.id}
            date={date}
            tour={tour}
            maxWidth={"480px"}
            width={"calc(12.5% - 24px)"}
            minWidth={"360px"}
            onClick={onClick}
            onClickTeam={onClickTeam}
            onDeleteTeam={onDeleteTeam}
          />
        ))}
      </Stack>
    </SortableContext>
  )

  return (
    <Box>
      <Typography
        variant={"h5"}
        sx={{
          mb: 3,
        }}
      >
        {title}
      </Typography>
      <Box my={2}>
        <Stack flexDirection={"row"} gap={2}>
          <Stack flex={1} gap={1}>
            <TextField fullWidth label={"TITLE"} value={notification?.title ?? ""} onChange={handleTitle}/>
            <TextField
              fullWidth
              multiline
              label={"NOTIFICATION"}
              value={notification?.body ?? ""}
              onChange={handleBody}
              rows={2}
            />
          </Stack>
          <Button variant={"contained"} onClick={handleSend}>
            Send
          </Button>
        </Stack>
      </Box>
      {
        disableDnd
          ? cards
          : (
            <DndContext
              autoScroll={false}
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              {cards}
            </DndContext>
          )
      }
    </Box>
  )

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event
    if (!over?.id || active.id === over.id) return
    const oldIndex = tours.findIndex(({id}) => id === active.id)
    const newIndex = tours.findIndex(({id}) => id === over.id)
    onDnd(arrayMove(tours, oldIndex, newIndex))
  }
}

export default function () {
  const {user, auth} = useAuth()
  const [dispatch, setDispatch] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string | null>(null)
  const [modal, setModal] = useState<boolean>(false)
  const [tourId, setTourId] = useState<string | null>(null)
  const [defaultTeamId, setDefaultTeamId] = useState<string | null>(null)
  const [orderedByTourName, setOrderedByTourName] = useState<boolean>(true)
  const [orderedByTourDepartureTime, setOrderedByTourDepartureTime] = useState<boolean>(false)
  const [useDnd, setUseDnd] = useState<boolean>(false);

  const [date, setDate] = useState<Dayjs | null>(dayjs())
  const [tab, setTab] = useState<'all' | 'seoul' | 'busan' | 'tokyo'>('all');
  const [serviceTab, setServiceTab] = useState<'operation' | 'dispatch' | 'railbike'>('operation');

  const operationRef = `/operation/${date!.format("YYYY-MM-DD")}`
  const {data: operation, loading, setData: setOperation} = useListen<Operation>(operationRef)
  const {data: products,} = useRead<{ [productId: string]: Product }>("/product")
  const {data: users,} = useListen<{ [userId: string]: User }>("/user")

  const {
    data: reservations,
    setData: setReservations,
    loading: reservationLoading
  } = useQueryListen<Reservation>('reservation', [['date', "==", date!.format("YYYY-MM-DD")]], date!.format("YYYY-MM-DD"));

  const defaultTeam = (tourId && defaultTeamId) ? operation?.tours?.[tourId]?.teams?.[defaultTeamId] ?? null : null;


  const filteredReservationList: (ReservationBase | Reservation)[] = reservations.filter(r => !r.canceledAt);
  const tourReservationList: (ReservationBase | Reservation)[] = [];

  const groupedTours = Object.values(operation?.tours ?? {}).reduce(
    (result, tour) => {
      const product = products?.[tour.productId]
      const category = product?.category ?? tour.productId.split("_")[1]

      const area = product?.area ?? tour.area
      const groupCategory = `${area}-${category}`
      if (!result[groupCategory]) result[groupCategory] = []
      if (
        !tour.teams ||
        !Object.values(tour.teams).find((team) => team.reservations || (team.guides && team.guides.length > 0))
      ) {
        return result;
      }

      tourReservationList.push(...Object.values(tour.teams).map(team => Object.values(team.reservations ?? {})).flat(1));

      tour.product = product?.name ?? tour.product
      result[groupCategory].push(tour)
      result[groupCategory] = result[groupCategory].sort((a, b) => {
        const productA = products?.[a.productId]
        const productB = products?.[b.productId]
        return orderedByTourDepartureTime
          ? Number.parseInt((productA?.winter ? productA?.departureTimeWinter : productA?.departureTime) ?? "9999") -
          Number.parseInt((productB?.winter ? productB?.departureTimeWinter : productB?.departureTime) ?? "9999")
          : orderedByTourName
            ? (productA?.name ?? a.product) > (productB?.name ?? b.product)
              ? 1
              : 0
            : a.idx - b.idx
      })
      return result
    },
    {} as { [area: string]: Tour[] }
  )

  const tourReservationMap = new Map(tourReservationList.map(r => [r.id, r]));
  const reservationMap = new Map(filteredReservationList.map((r) => [r.id, r]));


  const onlyTourHas = tourReservationList.filter(r => !reservationMap.has(r.id))
    .reduce((result, reservation) => {
      if (!result[reservation.productId]) {
        result[reservation.productId] = []
      }
      result[reservation.productId].push(reservation);
      return result;
    }, {} as { [tourId: string]: ReservationBase[] })

  const onlyReservationHas = filteredReservationList.filter(r => !tourReservationMap.has(r.id))
    .reduce((result, reservation) => {
      if (!result[reservation.productId]) {
        result[reservation.productId] = []
      }
      result[reservation.productId].push(reservation);
      return result;
    }, {} as { [tourId: string]: ReservationBase[] })


  const occupiedGuides = Object.fromEntries(
    Object.values(operation?.tours ?? {}).reduce(
      (result, tour) => {
        Object.values(tour.teams ?? {}).forEach((team) => {
          const mapped =
            team.guides?.map(
              ({id}) =>
                [
                  id,
                  {
                    id,
                    teamId: team.id,
                    tourId: tour.id,
                  },
                ] as [string, { id: string; tourId: string; teamId: string }]
            ) ?? []
          result.push(...mapped)
        })
        return result
      },
      [] as [string, { id: string; tourId: string; teamId: string }][]
    )
  )

  const handleChangeDate = (newDate: Dayjs | null) => {
    setDate(newDate)
  }

  const handleMemoChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const idx = Number.parseInt(event.target.name)
    const value = event.target.value ?? ""
    setOperation((operation) => {
      const memo = [...(operation?.memo ?? [])].map((m) => m ?? "")
      memo[idx] = value
      if (date) {
        const formattedDate = date?.format("YYYY-MM-DD");
        const operationRf = `operation/${formattedDate}`
        updateRealtime(operationRf, {memo})
          .then(() => {
            const changes = `Operation memo ${idx + 1} changed ${formattedDate}:[${operation?.memo?.[idx] ?? ''}=>${value}]`
            logAction('OPERATION', 'UPDATE OPERATION MEMO', operationRf, changes, {
              date: formattedDate,
              memo: value
            })
          })
          .catch(console.error)
      }
      return {...operation, memo}
    })
  }
  const handleDragEnd = (tours: UniqueTour[]) => {
    if (!useDnd) return;
    setOrderedByTourName(false)
    setOrderedByTourDepartureTime(false)
    window.setTimeout(() => {
      if (setOperation) {
        setOperation((operation) => {
          tours.forEach(({id}, idx) => {
            if (operation && operation.tours && operation.tours[id]) {
              operation.tours[id].idx = idx
            }
            if (date) {
              updateRealtime(`operation/${date?.format("YYYY-MM-DD")}/tours/${id}`, {idx}).catch(console.error)
            }
          })
          return {...operation}
        })
      }
    }, 500)
  }
  const handleTourClick = (tour: UniqueTour) => {
    setTourId(tour.id)
  }
  const handleTeamClick = (team: Team) => {
    setDefaultTeamId(team.id)
  }
  const handleDeleteTeam = (team: Team, tour: Tour) => {
    const path = `${operationRef}/tours/${tour.id}/teams/${team.id}`
    removeRealtime(path).catch(console.error)
  }

  const handleClose = () => {
    setTourId(null)
    setDefaultTeamId(null)
  }

  const handlePreDate = () => {
    setDate(date?.subtract(1, "day") ?? dayjs())
  }

  const handleNextDate = () => {
    setDate(date?.add(1, "day") ?? dayjs())
  }


  const handleNotification = (area: string) => (title: string, body: string, targets: string[]) => {
    const updatedNotification = {
      ...(operation?.notification ?? {}),
      [area]: {
        title,
        body,
        targets,
      },
    }

    setOperation({
      ...operation,
      notification: updatedNotification,
    })
  }

  const sendNotification = (area: string) => (title: string, body: string, targets: string[]) => {
    const operationRf = `operation/${date?.format("YYYY-MM-DD")}`
    updateRealtime(operationRf, {
      notification: operation?.notification ?? null,
    }).catch(console.error)
  }

  const handleOpenTourTeam = useCallback((tourId: string, teamId: string) => {
    setTourId(tourId)
    setDefaultTeamId(teamId);
  }, [setTourId, setDefaultTeamId])

  const handleToggleDnd = () => {
    setOrderedByTourName(false)
    setOrderedByTourDepartureTime(false)
    setUseDnd((prev) => !prev);
  }


  return (
    <>
      <>
        <ButtonBase
          disableTouchRipple
          onClick={handlePreDate}
          sx={{
            position: "fixed",
            top: "50%",
            left: "0.5em",
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          <ArrowLeftIcon
            sx={{
              width: "2em",
              height: "2em",
            }}
          />
        </ButtonBase>
        <ButtonBase
          disableTouchRipple
          onClick={handleNextDate}
          sx={{
            position: "fixed",
            top: "50%",
            right: "0.5em",
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          <ArrowRightIcon
            sx={{
              width: "2em",
              height: "2em",
            }}
          />
        </ButtonBase>
      </>
      <Stack px={8} gap={4} flexDirection={"column"}>
        <Card
          sx={{
            display: "inline-block",
            flex: 0,
          }}
        >
          <CardContent
            sx={(theme) => ({
              display: "flex",
              justifyContent: "space-between",
              color: theme.palette.text.primary,
              padding: "8px !important",
            })}
          >
            <DateTabs
              date={date?.toDate() ?? null}
              onChange={(date) => {
                handleChangeDate(dayjs(date))
              }}
            />
            <DatePicker format={"YY-MM-DD"} value={date} onChange={handleChangeDate}/>
          </CardContent>
        </Card>


        {
          (filteredReservationList[0]?.date === tourReservationList[0]?.date) && (Object.values(onlyTourHas).length > 0 || Object.values(onlyReservationHas).length > 0)
            ? <Card>
              {
                Object.values(onlyTourHas).length > 0
                  ? (
                    <CardContent>
                      <Typography variant={'h5'}>Operation에서만 발견</Typography>
                      {Object.entries(onlyTourHas).map(([productId, reservations]) => {
                        return (
                          <Box key={productId}>
                            <Typography
                              variant={'h6'}>{products?.[productId]?.name ?? productId}</Typography>
                            <Typography variant={'body2'} sx={{whiteSpace: 'pre-wrap'}}>
                              {reservations.map((r) => `${r.clientName}(${r.agency}/${r.agencyCode})`).join('\n')}
                            </Typography>
                          </Box>
                        )
                      })}
                    </CardContent>
                  )
                  : null
              }
              {
                Object.values(onlyReservationHas).length > 0
                  ? (
                    <CardContent>
                      <Typography variant={'h5'}>Reservation에서만 발견</Typography>
                      {Object.entries(onlyReservationHas).map(([productId, reservations]) => {
                        return (
                          <Box key={productId}>
                            <Typography variant={'h6'}>{products?.[productId].name}</Typography>
                            <Typography variant={'body2'} sx={{whiteSpace: 'pre-wrap'}}>
                              {reservations.map((r) => `${r.clientName}(${r.agency}/${r.agencyCode})`).join('\n')}
                            </Typography>
                          </Box>
                        )
                      })}
                    </CardContent>
                  )
                  : null
              }
            </Card>
            : null
        }

        <Card>
          <CardHeader title={date?.format("YYYY-MM-DD")}/>
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  multiline
                  id={date?.format("YYYY-MM-DD") + "/0"}
                  name={"0"}
                  label={"MEMO"}
                  variant={"outlined"}
                  placeholder={"MEMO 1"}
                  value={operation?.memo?.[0] ?? ""}
                  onChange={handleMemoChange}
                  rows={5}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  multiline
                  id={date?.format("YYYY-MM-DD") + "/1"}
                  name={"1"}
                  label={"MEMO"}
                  variant={"outlined"}
                  placeholder={"MEMO 2"}
                  value={operation?.memo?.[1] ?? "에버: \n" + "수습: \n" + "답사: \n" + "교육: \n" + "가능: "}
                  onChange={handleMemoChange}
                  rows={5}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/*{*/}
        {/*    operation*/}
        {/*        ?<TourReservationList operation={operation}/>*/}
        {/*        :null*/}
        {/*}*/}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Tabs value={tab} onChange={(_, value) => {
            if (dispatch) {
              if (value === 'all') {
                setTab('seoul');
                return;
              }
            }
            setTab(value)
          }}>
            <Tab value={'all'} label={'ALL'}/>
            <Tab value={'seoul'} label={'SEOUL'}/>
            <Tab value={'busan'} label={'BUSAN'}/>
            <Tab value={'tokyo'} label={'TOKYO'}/>
          </Tabs>

          <Tabs value={serviceTab} onChange={(_, value) => {
            if (tab === 'all' && value === 'dispatch') setTab('seoul');
            setServiceTab(value);
          }}>
            <Tab value={'operation'} label={'Operation'}/>
            <Tab value={'dispatch'} label={'Dispatch Bus'}/>
            <Tab value={'railbike'} label={'Rail Bike'}/>
          </Tabs>
        </Box>
        {
          serviceTab === 'dispatch' && products && operation && date && users
            ? <NewNewBus
              key={date?.format("YYYY-MM-DD")}
              date={date?.format("YYYY-MM-DD")}
              operation={operation}
              products={products}
              users={users}
              area={tab}
              onAction={handleOpenTourTeam}/>
            : serviceTab === 'railbike' && products && operation && date && users
              ? <Railbike date={date?.format('YYYY-MM-DD')}
                          operation={operation}
                          products={products}
                          users={users}
                          area={tab}/>
              : <>
                <Box
                  sx={(theme) => ({
                    color: theme.palette.text.disabled,
                  })}
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  gap={2}
                >
                  <Button
                    variant={"outlined"}
                    color={useDnd ? "primary" : "inherit"}
                    onClick={handleToggleDnd}
                  >
                    Drag n Drop
                  </Button>
                  <Button
                    variant={"outlined"}
                    color={orderedByTourName ? "primary" : "inherit"}
                    onClick={() => {
                      setOrderedByTourName(!orderedByTourName)
                      setOrderedByTourDepartureTime(false)
                    }}
                  >
                    abcㄱㄴㄷ
                  </Button>
                  <Button
                    variant={"outlined"}
                    color={orderedByTourDepartureTime ? "primary" : "inherit"}
                    onClick={() => {
                      setOrderedByTourDepartureTime(!orderedByTourDepartureTime)
                      setOrderedByTourName(false)
                    }}
                  >
                    Departure Time
                  </Button>

                  <Box>
                    <TextField
                      variant={"standard"}
                      placeholder={"search"}
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value ?? null)}
                    />
                  </Box>
                </Box>
                <SearchProvider keyword={keyword} setKeyword={setKeyword}>
                  {
                    Object.entries(groupedTours)
                      .filter(([key]) => {
                        return !(tab !== 'all' && !key.toLowerCase().includes(tab))
                      })
                      .sort(([a], [b]) => {
                        const aSplits = a.split("-")
                        const bSplits = b.split("-")
                        if (a.toLowerCase().includes("tokyo")) return 1
                        if (aSplits[0] === bSplits[0]) {
                          if (aSplits[1].toLowerCase() === "regular") {
                            return -1;
                          } else if (bSplits[1].toLowerCase() === "regular") {
                            return 1;
                          }
                          return aSplits[1] > bSplits[1] ? 1 : -1
                        }
                        return a > b ? -1 : 1
                      })
                      .map(([area, tours]) => (
                        <DnDTours
                          key={area}
                          title={area}
                          disableDnd={!useDnd}
                          date={date?.format("YYYY-MM-DD") ?? ""}
                          tours={tours}
                          notification={operation?.notification?.[area]}
                          onNotification={handleNotification(area)}
                          sendNotification={sendNotification(area)}
                          onDnd={handleDragEnd}
                          onClick={handleTourClick}
                          onClickTeam={handleTeamClick}
                          onDeleteTeam={handleDeleteTeam}
                        />
                      ))
                  }
                </SearchProvider>
              </>
        }
      </Stack>

      {tourId ? (
        <TourModal
          date={date?.format("YYYY-MM-DD") ?? ""}
          tourId={tourId}
          onClose={handleClose}
          defaultTeam={defaultTeam}
          occupiedGuides={occupiedGuides}
          keyword={keyword}
          products={products}
        />
      ) : null}
      {modal ? (
        <Modal open={modal}>
          <div></div>
        </Modal>
      ) : null}
    </>
  );
}

function CustomToolbar() {
  const apiRef = useGridApiContext()
  const rowIds = apiRef.current.getAllRowIds()
  const rows = rowIds.map(apiRef.current.getRow)
  const date = rows[0].date

  const schema = [
    {
      column: "Date",
      value: (r: any) => r.date,
    },
    {
      column: "ID",
      value: (r: any) => r.id,
    },
    {
      column: "Area",
      value: (r: any) => r.area,
    },
    {
      column: "Product",
      value: (r: any) => r.product,
    },
    {
      column: "Team",
      value: (r: any) => r.teamIdx,
    },
    {
      column: "Guide",
      value: (r: any) => r.guide,
    },
    {
      column: "Pickup",
      value: (r: any) => r.pickup,
    },
    {
      column: "name",
      value: (r: any) => r.name,
    },
    {
      column: "Nationality",
      value: (r: any) => r.nationality,
    },
    {
      column: "Language",
      value: (r: any) => r.language,
    },
    {
      column: "People",
      value: (r: any) => r.people,
    },
    {
      column: "Adult",
      value: (r: any) => r.adult,
    },
    {
      column: "Kid",
      value: (r: any) => r.kid,
    },
    {
      column: "Infant",
      value: (r: any) => r.infant,
    },
    {
      column: "Option",
      value: (r: any) => r.option,
    },
    {
      column: "Email",
      value: (r: any) => r.email,
    },
    {
      column: "Tel",
      value: (r: any) => r.tel,
    },
    {
      column: "Messenger",
      value: (r: any) => r.messenger,
    },
    {
      column: "Memo",
      value: (r: any) => r.memo,
    },
  ]

  const handleClick = () => {
    writeXlsxFile(rows, {
      schema,
      fileName: `kint2-${date}-reservations.xlsx`,
    }).then(() => {
    })
  }
  return (
    <GridToolbarContainer>
      <Button onClick={handleClick}>Excel</Button>
    </GridToolbarContainer>
  )
}

function TourReservationList(props: { operation: Operation }) {
  const operation = props.operation
  const groupedTours = Object.values(operation?.tours ?? {}).reduce(
    (result, tour) => {
      if (!result[tour.area]) result[tour.area] = []
      if (!tour.teams || !Object.values(tour.teams)[0].reservations) return result
      result[tour.area].push(tour)
      result[tour.area] = result[tour.area].sort(({idx: a}, {idx: b}) => a - b)
      return result
    },
    {} as { [area: string]: Tour[] }
  )

  const rows = Object.entries(groupedTours).flatMap(([area, tours]) => {
    const reservationsByTour = tours.map((tour) => {
      const reservationByTeam = Object.values(tour.teams ?? {}).map((team, idx) => {
        const teamIdx = idx + 1
        const guide = team.guides?.map((g) => g.name).join(", ") ?? ""
        const reservations = Object.values(team.reservations ?? {}).map((reservation) => {
          return {
            date: reservation.date,
            id: reservation.id,
            agency: reservation.agency,
            agencyCode: reservation.agencyCode,
            area: area,
            product: reservation.product,
            teamIdx: teamIdx,
            guide: guide,
            pickup: reservation.pickupPlace,
            name: reservation.clientName,
            nationality: reservation.nationality,
            language: reservation.language,
            people: reservation.adult + reservation.kid + reservation.infant,
            adult: reservation.adult,
            kid: reservation.kid,
            infant: reservation.infant,
            option:
              reservation.option && reservation.option.length > 0
                ? reservation.option.map((option) => `${option.option}(${option.people})`).join(", ")
                : "",
            email: reservation.email,
            tel: reservation.tel,
            messenger: reservation.messenger,
            memo: reservation.memo,
          }
        })
        return reservations
      })
      return reservationByTeam.flat(1)
    })
    return reservationsByTour.flat(2)
  })

  const columns: GridColDef[] = [
    {
      field: "date",
      headerName: "Date",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "id",
      headerName: "ID",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "area",
      headerName: "Area",
      minWidth: 50,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "product",
      headerName: "Product",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "teamIdx",
      headerName: "Team",
      minWidth: 50,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "guide",
      headerName: "Guide",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pickup",
      headerName: "Pickup",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "agency",
      headerName: "Agency",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "agencyCode",
      headerName: "Agency Code",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "nationality",
      headerName: "Nationality",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "language",
      headerName: "Language",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "people",
      headerName: "People",
      minWidth: 50,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "adult",
      headerName: "Adult",
      minWidth: 50,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "kid",
      headerName: "Kid",
      minWidth: 50,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "infant",
      headerName: "Infant",
      minWidth: 50,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "option",
      headerName: "Option",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tel",
      headerName: "Tel",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "messenger",
      headerName: "Messenger",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "memo",
      headerName: "Memo",
      minWidth: 300,
      align: "center",
      headerAlign: "center",
    },
  ]

  const schema = [
    {
      column: "Date",
      value: (r: any) => r.date,
    },
    {
      column: "ID",
      value: (r: any) => r.id,
    },
    {
      column: "Agency",
      value: (r: any) => r.agency,
    },
    {
      column: "Agency Code",
      value: (r: any) => r.agencyCode,
    },
    {
      column: "Area",
      value: (r: any) => r.area,
    },
    {
      column: "Product",
      value: (r: any) => r.product,
    },
    {
      column: "Team",
      value: (r: any) => r.teamIdx,
    },
    {
      column: "Guide",
      value: (r: any) => r.guide,
    },
    {
      column: "Pickup",
      value: (r: any) => r.pickup,
    },
    {
      column: "name",
      value: (r: any) => r.name,
    },
    {
      column: "Nationality",
      value: (r: any) => r.nationality,
    },
    {
      column: "Language",
      value: (r: any) => r.language,
    },
    {
      column: "People",
      value: (r: any) => r.people,
    },
    {
      column: "Adult",
      value: (r: any) => r.adult,
    },
    {
      column: "Kid",
      value: (r: any) => r.kid,
    },
    {
      column: "Infant",
      value: (r: any) => r.infant,
    },
    {
      column: "Option",
      value: (r: any) => r.option,
    },
    {
      column: "Email",
      value: (r: any) => r.email,
    },
    {
      column: "Tel",
      value: (r: any) => r.tel,
    },
    {
      column: "Messenger",
      value: (r: any) => r.messenger,
    },
    {
      column: "Memo",
      value: (r: any) => r.memo,
    },
  ]

  const handleClick = () => {
    writeXlsxFile(rows, {
      schema,
      fileName: `kint2-${rows[0]?.date ?? ""}-reservations.xlsx`,
    }).then(() => {
    })
  }

  return (
    // <Button onClick={handleClick} variant={"outlined"}>
    //     Excel
    // </Button>
    <Card>
      <CardContent>
        <DataGridPro columns={columns} rows={rows} slots={{toolbar: CustomToolbar}}/>
      </CardContent>
    </Card>
  )
}

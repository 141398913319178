import React, {useEffect, useMemo, useState} from "react";
import {DateRange, DateRangePicker, SingleInputDateRangeField} from "@mui/x-date-pickers-pro";
import dayjs, {Dayjs} from "dayjs";
import {useRead, useReadPropValueStartEndAt} from "../../../hooks/realtime";
import {readWithKeyStartEndAt} from "../../../hooks/firebase";
import {Operation} from "../../../models/Operation";
import {Product} from "../../../models/Product";
import {User} from "../../../models/User";
import {Balance} from "../../../models/Balance";
import {
  DataGridPro,
  GridColDef, gridFilterModelSelector,
  GridHeaderFilterCellProps,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {Pickup} from "../../../models/Pickup";
import {InputLabel, Select, SelectChangeEvent} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import writeXlsxFile from "write-excel-file";
import Stack from "@mui/material/Stack";

type Operations = { [date: string]: Operation };
type Balances = { [id: string]: Balance };
type Users = { [id: string]: User };
type Products = { [id: string]: Product }

type Count = { count: number, people: number, adult: number, kid: number, infant: number };

type GuideTourEntity = {
  guideId: string,
  guideName: string,
  date: string, product: string, present: Count, noShow: Count, total: Count, pickupSet: Set<string>,
  productId: string
};

type GuideOperationEntity = {
  guideId: string,
  guideName: string,
  date: string,
  product: string,
  people: number,
  adult: number,
  kid: number,
  count: number | string,
  pickup: string,
  pay: number | string,
  incentive: number | string,
}

type GuideOperationRow = Partial<GuideOperationEntity> & {
  id: string,
  day: string,
  guideId: string,
  guideName: string,
  groupPath: string[]
  cardExpenditure: string | number,
  cardIncome: string | number,
  cashExpenditure: string | number,
  cashIncome: string | number,
  memo?: string
  _date?: string,
  _guide?: string,
  _product?: string,
}

type SelectOption = { id: string, name: string };
type GuideSelectOption = SelectOption;
type ProductSelectOption = SelectOption;


export default function () {
  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>(getLastWeekDateRange());
  const [tempDateRange, setTempDateRange] = React.useState<DateRange<Dayjs>>(dateRange);
  const formatDateRanges = useMemo(() => dateRange.map(djs => (djs ?? dayjs()).format('YYYY-MM-DD')), [dateRange]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadKey, setLoadKey] = useState<string>(Date.now() + '');
  const [operations, setOperations] = useState<{ [formattedDate: string]: Operation }>({});
  const {data: products,} = useRead<Products>('/product');
  const {data: users,} = useRead<Users>('/user');
  const {
    data: balances,
    save,
  } = useReadPropValueStartEndAt<Balance>('account', 'date', formatDateRanges[0], formatDateRanges[1], loadKey);
  const [selectedGuideOptions, setSelectedGuideOptions] = useState<GuideSelectOption[]>([]);
  const [selectedProductOptions, setSelectedProductOptions] = useState<ProductSelectOption[]>([]);
  const selectedProductIds = useMemo(() => selectedProductOptions.map(({id}) => id), [selectedProductOptions]);
  const selectedGuideIds = useMemo(() => selectedGuideOptions.map(({id}) => id), [selectedGuideOptions]);
  const productSelectOptions = useMemo(() => Object.values(products ?? {})
    .filter((product) => product.status === 'ON' && !product.deletedAt)
    .map(product => ({
      id: product.id, name: `${product.name}`
    })), [products])
  const guideSelectOptions = useMemo(() => Object.values(users ?? {})
    .filter((user) => user.on)
    .map(user => ({
      id: user.id, name: `${user.name}(${user.nameEn})`
    })), [users]);
  useEffect(() => {
    setLoading(true);
    readWithKeyStartEndAt<Operation>('/operation', formatDateRanges[0], formatDateRanges[1])
      .then((operations) => {
        setOperations(operations);
      })
      .finally(() => {
        setLoading(false);
      });
  }, formatDateRanges)

  const handleTempDateRange = (dr: DateRange<Dayjs>) => {
    setTempDateRange(dr);
  }
  const handleConfirmDateRange = () => {
    setDateRange(tempDateRange);
  }
  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Box p={1}>
            <Card>
              <CardContent sx={(theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                color: theme.palette.text.primary,
                gap: '24px',
                padding: '8px !important'
              })}>
                <Box sx={{
                  width: '100%',
                  flexGrow: 1,
                  display: 'flex',
                  gap: '8px',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>
                  <Box sx={{
                    flexGrow: 1,
                  }}>
                    <Autocomplete
                      multiple
                      options={guideSelectOptions}
                      value={selectedGuideOptions}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(_, values) => {
                        setSelectedGuideOptions(values);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label={'가이드'}
                          InputLabelProps={{shrink: true}}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{
                    flexGrow: 1,
                  }}>
                    <Autocomplete
                      multiple
                      options={productSelectOptions}
                      value={selectedProductOptions}
                      getOptionLabel={option => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(_, values) => {
                        setSelectedProductOptions(values);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label={'투어 상품'}
                          InputLabelProps={{shrink: true}}
                        />
                      )}
                    />
                  </Box>
                </Box>

                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  minWidth: '280px',
                }}>
                  <DateRangePickerValue onChange={handleTempDateRange} dateRange={dateRange}/>
                  <Button
                    sx={{height: '100%'}}
                    onClick={handleConfirmDateRange}
                    variant={'contained'}
                    disabled={dateRange.map(d => d?.format('YYYY-MM-DD')).join() === tempDateRange.map(d => d?.format('YYYY-MM-DD')).join()}
                  >
                    {
                      loading ?
                        <CircularProgress/>
                        :
                        'Confirm'
                    }
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <GuideTourList
                selectedGuideIds={selectedGuideIds}
                selectedProductIds={selectedProductIds}
                operations={operations}
                products={products ?? {}}
                users={users ?? {}}
                balances={balances ?? {}}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}


function DateRangePickerValue(props: { dateRange: DateRange<Dayjs>, onChange: (dr: DateRange<Dayjs>) => void }) {
  const {dateRange: value, onChange: setValue} = props;
  return (

    <DateRangePicker
      format={'YY-MM-DD'}
      slots={{field: SingleInputDateRangeField}}
      value={value}
      onChange={(newValue) => setValue(newValue)}
    />
  );
}

function GuideTourList(props: {
  selectedGuideIds: string[],
  selectedProductIds: string[],
  operations: Operations, products: Products, users: Users, balances: Balances
}) {
  const {selectedGuideIds, selectedProductIds, operations, products, users, balances} = props;
  const [rowUpdates, setRowUpdates] = useState<{ [rowId: string]: GuideOperationRow }>({});
  const handleRowChange = (newRow: GuideOperationRow) => {
    setRowUpdates((updates) => ({...updates, [newRow.id]: newRow}));
    return newRow;
  };

  const sortedBalances = Object.entries(balances).reduce((result, [id, balance]) => {
    const guideId = balance.writer.split(')').at(-1) ?? "";
    const date = balance.date;
    balance.identifier = id;
    if (!result[date]) {
      result[date] = {}
    }

    if (!result[date][guideId]) {
      result[date][guideId] = []
    }

    result[date][guideId].push(balance);
    return result;
  }, {} as { [date: string]: { [id: string]: Balance[] } })

  const tourGuideReducedReservationTuples = Object.entries(operations).map(([date, {tours}]) => {
    const tourList = Object.values(tours ?? {});
    return tourList.map((tour) => {
      const product = products?.[tour.productId]?.name ?? tour.product;
      if (selectedProductIds.length !== 0 && !selectedProductIds.includes(tour.productId)) return [];
      const teams = Object.values(tour.teams ?? {})
      return teams.map<[string, GuideTourEntity][]>((team, teamIdx) => {
        const guides = team.guides
        const reducedReservations = Object.values(team.reservations ?? {}).reduce((result, reservation) => {
            const adult = reservation.adult;
            const kid = reservation.kid;
            const infant = reservation.infant;
            const people = adult + kid + infant;
            if (reservation.noShow) {
              result.noShow.adult += adult;
              result.noShow.kid += kid;
              result.noShow.infant += infant;
              result.noShow.people += people;
              result.noShow.count += 1;
            } else {
              result.present.adult += adult;
              result.present.kid += kid;
              result.present.infant += infant;
              result.present.people += people;
              result.present.count += 1;
            }
            result.total.adult += adult;
            result.total.kid += kid;
            result.total.infant += infant;
            result.total.people += people;
            result.total.count += 1;
            result.pickupSet.add(reservation.pickupPlace);
            return result;
          }, ({
            date,
            product,
            present: {count: 0, people: 0, adult: 0, kid: 0, infant: 0},
            noShow: {count: 0, people: 0, adult: 0, kid: 0, infant: 0},
            total: {count: 0, people: 0, adult: 0, kid: 0, infant: 0},
            pickupSet: new Set<string>(),
            productId: tour.productId,
          } as GuideTourEntity)
        )
        return (guides?.filter((g) => selectedGuideIds.length === 0 || selectedGuideIds.includes(g.id))
          .map(g => [g.id, {...reducedReservations, guideName: g.name,}]) ?? [])
      }).flat(1);
    }).flat(1)
  }).flat(1)

  const reduced = tourGuideReducedReservationTuples.reduce((result, [guideId, entity]) => {
    if (!result[guideId]) result[guideId] = [];
    result[guideId].push(entity);
    return result;
  }, ({} as { [guideId: string]: GuideTourEntity[] }))

  const sorted = Object.fromEntries(Object.entries(reduced).map(([guideId, entities]) => [guideId, entities.sort((a, b) => a.date > b.date ? 1 : -1)]));
  const totalGuideRows: GuideOperationRow[] = [];
  const totalEntityRows: GuideOperationRow[] = [];
  const totalBalanceRows: GuideOperationRow[] = [];

  const rows: GuideOperationRow[] = Object.entries(sorted)
    .sort(([_, aEntities], [__, bEntities]) => {
      if (aEntities.length > bEntities.length) return -1;
      if (aEntities.length < bEntities.length) return 1;
      if (aEntities[0].date > bEntities[0].date) return 1;
      if (aEntities[0].date < bEntities[0].date) return -1;
      return 0
    })
    .map<GuideOperationRow[]>(([guideId, entities]) => {
      const guide = users?.[guideId]
      const guideName = `${guide?.name ?? entities.at(0)?.guideName}(${guide?.nameEn ?? guideId})`;
      const dates = entities.map((entity) => entity.date)
      const product = entities.map((entity) => entity.product).join(', ')
      const count = entities.length;
      const balancesMap: Map<string, GuideOperationRow> = new Map();
      const guideIdentifier = guideName + (guide?.email?.split('@').at(0) ?? '');
      const entityRows = entities.map<GuideOperationRow>((e) => {
        const id = guideId + e.date;
        const pickupOrders = Object.values(products[e.productId]?.chat?.pickup ?? {}).sort((a, b) => (a.order ?? 9999) > (b.order ?? 9999) ? 1 : -1).map(p => p.place)
        const filteredPickupOrders = pickupOrders.filter((p) => e.pickupSet.has(p));
        const balanceRows = sortedBalances[e.date]?.[guideId]?.map((balance, idx) => {
          return {
            id: balance.identifier,
            guideId: '',
            guideName: '',
            date: '',
            day: '',
            count: '',
            product: '',
            pay: '',
            incentive: '',
            groupPath: [guideIdentifier, e.date, balance.identifier],
            cardExpenditure: (balance?.card ?? 0) < 0 ? balance.card + '' : '',
            cardIncome: (balance?.card ?? 0) > 0 ? balance.card + '' : '',
            cashExpenditure: (balance?.cash ?? 0) < 0 ? balance.cash + '' : '',
            cashIncome: (balance?.cash ?? 0) > 0 ? balance.cash + '' : '',
            memo: balance.detail,
            _date: e.date,
            _product: e.product,
            _guide: guideIdentifier
          }
        }) ?? []

        const guideSum = balanceRows.reduce((result, balance) => {
          balancesMap.set(balance.id, balance);
          if (balance.cardExpenditure)
            result.cardExpenditure += Number.parseInt(balance.cardExpenditure);
          if (balance.cardIncome)
            result.cardIncome += Number.parseInt(balance.cardIncome);
          if (balance.cashExpenditure)
            result.cashExpenditure += Number.parseInt(balance.cashExpenditure);
          if (balance.cashIncome)
            result.cashIncome += Number.parseInt(balance.cashIncome);

          return result;
        }, {cardExpenditure: 0, cardIncome: 0, cashExpenditure: 0, cashIncome: 0} as {
          cardExpenditure: number,
          cardIncome: number,
          cashExpenditure: number,
          cashIncome: number
        });
        return (rowUpdates[id]
          ? {...rowUpdates[id]}
          : {
            ...e,
            ...e.total,
            id,
            guideId,
            guideName,
            date: e.date.split('-').at(-1),
            day: ['일', '월', '화', '수', '목', '금', '토'][dayjs(e.date).day()],
            pickup: filteredPickupOrders?.[0] ?? '',
            groupPath: [guideIdentifier, e.date],
            pay: 0,
            incentive: 0,
            cardExpenditure: guideSum.cardExpenditure,
            cardIncome: guideSum.cardIncome,
            cashExpenditure: guideSum.cashExpenditure,
            cashIncome: guideSum.cashIncome,
            _date: e.date,
            _product: e.product,
            _guide: guideIdentifier
          })
      })
      const balancesRow = [...balancesMap.values()];
      const totalPay = entityRows.map((e) => e.pay).reduce((a, b) => Number.parseInt((a ?? 0) + '') + Number.parseInt((b ?? 0) + ''));
      const totalIncentive = entityRows.map((e) => e.incentive).reduce((a, b) => Number.parseInt((a ?? 0) + '') + Number.parseInt((b ?? 0) + ''));
      const totalCardExpenditure = ([...balancesRow.map(b => b.cardExpenditure ? Number.parseInt(b.cardExpenditure + '') : 0), 0]).reduce((a, b) => a + b);
      const totalCardIncome = ([...balancesRow.map(b => b.cardIncome ? Number.parseInt(b.cardIncome + '') : 0), 0]).reduce((a, b) => a + b);
      const totalCashExpenditure = ([...balancesRow.map(b => b.cashExpenditure ? Number.parseInt(b.cashExpenditure + '') : 0), 0]).reduce((a, b) => a + b);
      const totalCashIncome = ([...balancesRow.map(b => b.cashIncome ? Number.parseInt(b.cashIncome + '') : 0), 0]).reduce((a, b) => a + b);
      const guideRow: GuideOperationRow = {
        id: guideId,
        guideId,
        guideName,
        date: dates.map((d: string) => d.split('-').at(-1)).join(', '),
        day: dates.map((d: string) => ['일', '월', '화', '수', '목', '금', '토'][dayjs(d).day()]).join(', '),
        count,
        product,
        groupPath: [guideIdentifier],
        pay: totalPay,
        incentive: totalIncentive,
        cardExpenditure: totalCardExpenditure,
        cardIncome: totalCardIncome,
        cashExpenditure: totalCashExpenditure,
        cashIncome: totalCashIncome
      };

      totalGuideRows.push(guideRow);
      totalEntityRows.push(...entityRows);
      totalBalanceRows.push(...balancesRow);

      return [guideRow, ...entityRows, ...balancesRow];
    }).flat(1);

  const getRowStyle = (priority: number, value: number) => {
    switch (priority) {
      case 1:
        return {
          fontSize: '16px',
          color: 'white',
          backgroundColor: value === 0 ? undefined : value > 0 ? 'rgba(0,0,255, 0.5)' : 'rgba(255,0,0, 0.5)'
        };
      case 2:
        return {
          fontSize: '16px',
          backgroundColor: value === 0 ? undefined : value > 0 ? 'rgba(0,0,255, 0.1)' : 'rgba(255,0,0, 0.1)'
        };
      case 3:
        return {
          fontSize: '16px',
          backgroundColor: value === 0 ? undefined : value > 0 ? 'rgba(0,0,255, 0.0)' : 'rgba(255,0,0, 0.0)'
        };
      default:
        return {};
    }
  };

  const columns: GridColDef[] = [
    {field: 'date', headerName: 'DATE', minWidth: 200, align: 'left', headerAlign: 'center'},
    {field: 'day', headerName: 'DAY', minWidth: 200, align: 'left', headerAlign: 'center'},
    {field: 'product', headerName: 'PRODUCT', minWidth: 300, align: 'left', headerAlign: 'center'},
    {field: 'count', headerName: 'COUNT', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'people', headerName: 'PEOPLE', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'adult', headerName: 'ADULT', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'kid', headerName: 'KID', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'infant', headerName: 'INFANT', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'pickup', headerName: 'PICK UP', minWidth: 300, align: 'left', headerAlign: 'center'},
    {
      field: 'incentive', headerName: 'INCENTIVE', minWidth: 100, align: 'left', headerAlign: 'center', editable: true,
      valueParser: (value: any) => {
        const newVar = typeof value === 'string' ? Number.parseInt(value) : value ?? 0;
        return newVar;
      },
    },
    {
      field: 'pay', headerName: 'PAY', minWidth: 100, align: 'left', headerAlign: 'center', editable: true,
      valueParser: (value: any) => {
        const newVar = typeof value === 'string' ? Number.parseInt(value) : value ?? 0;
        return newVar;
      },
    },

    {
      field: 'cardExpenditure',
      headerName: 'CARD EXP',
      minWidth: 100,
      align: 'left',
      headerAlign: 'center',
      editable: true,
      valueParser: (value: any) => {
        const newVar = typeof value === 'string' ? Number.parseInt(value) : value ?? 0;
        return newVar;
      },
      renderCell: (prop) => {
        const value = prop.row.cardExpenditure;
        const newVar = typeof value === 'string' ? Number.parseInt(value || '0') : value ?? 0;
        return <Typography
          sx={
            {
              ...getRowStyle(prop.row.groupPath?.length ?? 0, prop.row.cardExpenditure)
            }
          }

          color={
            prop.row.cardExpenditure > 0
              ? '#1577BA'
              : prop.row.cardExpenditure < 0
                ? '#FF4C41'
                : 'inherit'
          }>
          {
            newVar !== 0
              ?
              newVar.toLocaleString()
              : ''
          }
        </Typography>
      },
    },
    {
      field: 'cardIncome', headerName: 'CARD IN', minWidth: 100, align: 'left', headerAlign: 'center', editable: true,
      valueParser: (value: any) => {
        const newVar = typeof value === 'string' ? Number.parseInt(value) : value ?? 0;
        return newVar;
      },
      renderCell: (prop) => {
        const value = prop.row.cardIncome;
        const newVar = typeof value === 'string' ? Number.parseInt(value || '0') : value ?? 0;
        return <Typography
          sx={
            {
              ...getRowStyle(prop.row.groupPath?.length ?? 0, prop.row.cardIncome)
            }
          }
          color={
            prop.row.cardIncome > 0
              ? '#1577BA'
              : prop.row.cardIncome < 0
                ? '#FF4C41'
                : 'inherit'
          }>
          {
            newVar !== 0
              ?
              newVar.toLocaleString()
              : ''
          }
        </Typography>
      },
    },

    {
      field: 'cashExpenditure',
      headerName: 'CASH EXP',
      minWidth: 100,
      align: 'left',
      headerAlign: 'center',
      editable: true,
      valueParser: (value: any) => {
        const newVar = typeof value === 'string' ? Number.parseInt(value) : value ?? 0;
        return newVar;
      },
      renderCell: (prop) => {
        const value = prop.row.cashExpenditure;
        const newVar = typeof value === 'string' ? Number.parseInt(value || '0') : value ?? 0;
        return <Typography
          sx={
            {
              ...getRowStyle(prop.row.groupPath?.length ?? 0, prop.row.cashExpenditure)
            }
          }
          color={
            prop.row.cashExpenditure > 0
              ? '#1577BA'
              : prop.row.cashExpenditure < 0
                ? '#FF4C41'
                : 'inherit'
          }>
          {
            newVar !== 0
              ?
              newVar.toLocaleString()
              : ''
          }
        </Typography>
      },
    },
    {
      field: 'cashIncome', headerName: 'CASH IN', minWidth: 100, align: 'left', headerAlign: 'center', editable: true,
      renderCell: (prop) => {
        const value = prop.row.cashIncome;
        const newVar = typeof value === 'string' ? Number.parseInt(value || '0') : value ?? 0;
        return <Typography
          sx={
            {
              ...getRowStyle(prop.row.groupPath?.length ?? 0, prop.row.cashIncome)
            }
          }
          color={
            prop.row.cashIncome > 0
              ? '#1577BA'
              : prop.row.cashIncome < 0
                ? '#FF4C41'
                : 'inherit'
          }>
          {
            newVar !== 0
              ?
              newVar.toLocaleString()
              : ''
          }
        </Typography>
      },
    },
    {field: 'memo', headerName: 'MEMO', minWidth: 400, align: 'left', headerAlign: 'center'},
  ]

  return(
  <Stack flexDirection={'column'} gap={'16px'}>
    <Box width={'100%'} sx={{display:'flex', flexDirection:'row', gap:'16px', justifyContent:'flex-end'}}>
      <DownloadButton title={'가이드 투어 내역'} schema={[
        {
          column: '가이드',
          value: (r: any) => r._guide,
        },
        {
          column: '날짜',
          value: (r: any) => r._date,
        },
        {
          column: '투어',
          value: (r: any) => r._product,
        },
        {
          column: '예약수',
          value: (r: any) => r.count,
        },
        {
          column: '인원수',
          value: (r: any) => r.people,
        },
        {
          column: '픽업',
          value: (r: any) => r.pickup
        },
        {
          column: "카드 지출",
          value: (r: any) => r.cardExpenditure,
        },
        {
          column: '카드 수입',
          value: (r: any) => r.cardIncome,
        },
        {
          column: '현금 지출',
          value: (r: any) => r.cashExpenditure,
        },
        {
          column: '현금 수입',
          value: (r: any) => r.cashIncome,
        },
      ]} gridRows={totalEntityRows}/>
      <DownloadButton title={'가이드 수입 지출 내역'} schema={[
        {
          column: '가이드',
          value: (r: any) => r._guide,
        },
        {
          column: '날짜',
          value: (r: any) => r._date,
        },
        {
          column: '투어',
          value: (r: any) => r._product,
        },
        {
          column: "카드 지출",
          value: (r: any) => r.cardExpenditure,
        },
        {
          column: '카드 수입',
          value: (r: any) => r.cardIncome,
        },
        {
          column: '현금 지출',
          value: (r: any) => r.cashExpenditure,
        },
        {
          column: '현금 수입',
          value: (r: any) => r.cashIncome,
        },
        {
          column: '메모',
          value: (r: any) => r.memo
        }
      ]} gridRows={totalBalanceRows}/>
    </Box>
    <DataGridPro
      treeData
      rows={rows}
      columns={columns}
      getTreeDataPath={(r) => r.groupPath}
      processRowUpdate={handleRowChange}
      onProcessRowUpdateError={console.error}
    />
  </Stack>
  )
}

function DownloadButton(props: {
  title: string,
  schema: { column: string, value: (r: any) => string | number }[],
  gridRows: GuideOperationRow[]
}) {
  const {title, schema, gridRows} = props;

  const handleClickDownload = () => {
    writeXlsxFile(gridRows, {
      schema,
      fileName: `${title}.xlsx`
    })
  }

  return (
    <Button onClick={handleClickDownload} variant={'outlined'}>
      {title}({gridRows.length}) 다운로드
    </Button>
  )
}

function getLastWeekDateRange(): [Dayjs, Dayjs] {
  const today = new Date();
  const lastWeekDate = new Date(today);
  lastWeekDate.setDate(lastWeekDate.getDate() - 7);

  const dayOfWeek = lastWeekDate.getDay(); // 일요일: 0, 월요일: 1, ..., 토요일: 6
  const monday = new Date(lastWeekDate);
  monday.setDate(lastWeekDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));
  const sunday = new Date(lastWeekDate);
  sunday.setDate(lastWeekDate.getDate() + (dayOfWeek === 0 ? 0 : 7 - dayOfWeek));
  return [dayjs(monday), dayjs(sunday)];
}




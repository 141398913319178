import React, {useCallback, useMemo, useState} from 'react';

import Box from '@mui/material/Box';
import ButtonBase from "@mui/material/ButtonBase";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputBase from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from "@mui/material/Typography";
import Switch from '@mui/material/Switch';
import FormControlLabel from "@mui/material/FormControlLabel";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";


import {useQueryDocs, useQueryListen} from "../../hooks/firestore";
import {Reservation} from "../../models/Reservation";


import dayjs, {Dayjs} from 'dayjs';
import {DateRange, DateRangePicker, SingleInputDateRangeField} from '@mui/x-date-pickers-pro';
import {
  DataGridPro,
  GridToolbar,
  FilterColumnsArgs,
  GetColumnForNewFilterArgs,
  GridColDef,
  GridActionsCellItem,
  GridHeaderFilterCellProps,
  useGridApiContext,
  getDefaultGridFilterModel,
  useGridSelector, gridFilterModelSelector, GridRowSelectionModel,
  getGridStringOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators, GridToolbarQuickFilter, GridRowParams
} from '@mui/x-data-grid-pro';
import {GridEventListener} from "@mui/x-data-grid/models/events";

import StarIcon from "@mui/icons-material/Star";
import StarOutlinedIcon from "@mui/icons-material/StarOutline";
import NoShowIcon from "@mui/icons-material/NoAccounts";
import NoShowOutlinedIcon from "@mui/icons-material/NoAccountsOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FiberNewIcon from '@mui/icons-material/FiberNew';
import NoteIcon from '@mui/icons-material/EditNote';
import ReservationModal from "../../partials/ReservationModal";
import {
  logAction,
  readWithKeyStartEndAt,
  updateBatchFireStore,
  updateFireStore,
  updateRealtime
} from "../../hooks/firebase";
import CreateReservationModal from "../../partials/CreateReservationModal";
import {useListen, useRead, useReadKeyStartAtEndAt} from "../../hooks/realtime";
import DateTabs from "../../components/DateTabs";
import {Product} from "../../models/Product";
import {Pickup} from "../../models/Pickup";
import {capitalize, InputLabel, Select, SelectChangeEvent} from "@mui/material";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
} from 'chart.js';
import {Doughnut, Bar} from 'react-chartjs-2';
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Icon from '@mui/material/Icon';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

import ReservationIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutline';
import {Operation, Tour} from "../../models/Operation";
import writeXlsxFile from "write-excel-file";
import {useAuth} from "../../hooks/auth";
import {update} from "firebase/database";
import DoubleCheck from "./DoubleCheck";
import {ReservationRow} from './type';
import CountOptions from "./CountOptions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
);

type ReservationRows = {

  total: ReservationRow[],
  all: ReservationRow[],
  present: ReservationRow[],
  noShow: ReservationRow[],
  star: ReservationRow[],
  noStar: ReservationRow[],
  check: ReservationRow[],
  cancel: ReservationRow[],
  outsource: ReservationRow[],
}

type ReservationAggregation = {
  count: number,
  people: number,
  reservations: Reservation[]
}


type Products = { [productKey: string]: Product }

function DateRangePickerValue(props: { dateRange: DateRange<Dayjs>, onChange: (dr: DateRange<Dayjs>) => void }) {
  const {dateRange: value, onChange: setValue} = props;
  return (

    <DateRangePicker
      format={'YY-MM-DD'}
      slots={{field: SingleInputDateRangeField}}
      value={value}
      onChange={(newValue) => setValue(newValue)}
    />
  );
}

type ReservationCategoryType = 'all' | 'present' | 'noShow' | 'cancel'

const ALL = 'ALL'

function ReservationCharts(props: { reservations: Reservation[], products: Products }) {
  const [tab, setTab] = useState<ReservationCategoryType>('all');
  const allReservations = reservationStatics(props.reservations);
  const categorizedReservations = reservationCategorize(props.reservations, props.products);
  const handleSetTab = (e: any, tab: any) => {
    setTab(tab)
  }

  const {data: pickups} = useRead<{ [areaName: string]: any }>('/pickup');
  const pickupOrder = Object.entries(pickups ?? {}).reduce((result, [areaName, pickups]) => {
    const capitalizedAreaName = areaName.charAt(0).toUpperCase() + areaName.slice(1);
    result[capitalizedAreaName] = Object.keys(pickups);
    return result;
  }, {} as { [areaName: string]: string[] });

  return (
    <Box
      height={'100%'}
    >
      <Box
        height={'100%'}
      >
        <Stack gap={2} flexDirection={'row'}>
          {
            Object.entries(pickupOrder).map(([areaName, pickups]) => {
              const labels = pickups.map((p) => `${p}(${categorizedReservations?.place?.[areaName]?.[p]?.[tab].counts ?? 0}/${categorizedReservations?.place?.[areaName]?.[p]?.[tab].people ?? 0})`)
              const countsData = pickups.map((p) => categorizedReservations?.place?.[areaName]?.[p]?.[tab].counts ?? 0);
              const peopleData = pickups.map((p) => categorizedReservations?.place?.[areaName]?.[p]?.[tab].people ?? 0);
              const data = {
                labels: labels,
                datasets: [
                  {
                    label: 'Reservations',
                    data: countsData,
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                  },
                  {
                    label: 'People',
                    data: peopleData,
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                  },
                ],
              };

              return (
                <Box
                  sx={{
                    width: '24%',
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    my: 2,
                  }}
                >
                  <Doughnut
                    data={data}
                  />
                  <Typography
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      textAlign: 'center',
                    }}
                    variant={'h6'}
                  >
                    {capitalize(areaName)}<br/>
                    {countsData.reduce((a, b) => a + b)}/{peopleData.reduce((a, b) => a + b)}
                  </Typography>
                </Box>
              )
            })
          }
          {

            Object.entries(pickupOrder).map(([areaName, pickups]) => {
              const data = Object.entries(categorizedReservations.product)
                .filter(([productName, {area, ...statics}]) => area === areaName)
                .map(([productName, {area, ...statics}]) => ([productName, statics]))
                .reduce((result, [productName, statics]) => {
                  result.labels.push(productName as string);
                  if (tab === 'all' || tab === 'present')
                    result.datasets[0].data.push((statics as {
                      present: any,
                      noShow: any,
                      cancel: any
                    }).present.counts)
                  if (tab === 'all' || tab === 'noShow')
                    result.datasets[1].data.push((tab === 'all' ? -1 : 1) * (statics as {
                      present: any,
                      noShow: any,
                      cancel: any
                    }).noShow.counts)
                  if (tab === 'all' || tab === 'cancel')
                    result.datasets[2].data.push((tab === 'all' ? -1 : 1) * (statics as {
                      present: any,
                      noShow: any,
                      cancel: any
                    }).cancel.counts)
                  return result;
                }, {
                  labels: [] as string[],
                  datasets: [
                    {
                      label: 'Present',
                      data: [] as number[],
                      backgroundColor: 'rgb(255, 99, 132)',
                    },
                    {
                      label: 'No Show',
                      data: [] as number[],
                      backgroundColor: 'rgb(75, 192, 192)',
                    },
                    {
                      label: 'Cancel',
                      data: [] as number[],
                      backgroundColor: 'rgb(53, 162, 235)',
                    },
                  ]
                });
              if (tab === 'present')
                data.datasets.splice(1, 2)
              if (tab === 'noShow') {
                data.datasets.splice(0, 1)
                data.datasets.splice(1, 1)
              }
              if (tab === 'cancel')
                data.datasets.splice(0, 2)
              return (
                <Box
                  sx={{
                    width: '48%',
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Bar data={data} options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      x: {stacked: true},
                      y: {stacked: true}
                    }
                  }}/>
                </Box>
              )
            })
          }
        </Stack>
      </Box>
      <Box>
      </Box>
    </Box>
  )
}

function reservationCategorize(reservations: Reservation[], products: Products) {
  const categorized: {
    place: {
      [area: string]: {
        [pickup: string]: Reservation[]
      }
    },
    product: {
      [product: string]: {
        area: string,
        reservations: Reservation[]
      }
    }
  } = {place: {}, product: {}};

  reservations.reduce((result, reservation) => {
    const product: Product = products[reservation.productId];
    const productName = product?.name ?? reservation.product;
    const area = product?.area ?? 'Seoul';
    const pickup = reservation.pickupPlace;

    if (!result.product[productName]) result.product[productName] = {area, reservations: []};
    if (!result.place[area]) result.place[area] = {};
    if (!result.place[area][pickup]) result.place[area][pickup] = [];

    result.product[productName].reservations.push(reservation);
    result.place[area][pickup].push(reservation);

    return result;
  }, categorized);

  const toStatics = (categorized: {
    [k: string]: Reservation[]
  }) => Object.fromEntries(Object.entries(categorized).map(([product, reservations]) => [product, reservationStatics(reservations)]))
  return {
    product: {
      ...Object.fromEntries(Object.entries(categorized.product).map(([productName, {
        area,
        reservations
      }]) => [productName, {area, ...reservationStatics(reservations)}]))
    },
    place: {
      ...Object.fromEntries(Object.entries(categorized.place).map(([area, pickupReservations]) => [area, toStatics(pickupReservations)]))
    }
  }
}

function reservationStatics(reservations: Reservation[]) {
  return {
    all: countWithFilter(reservations, () => true),
    present: countWithFilter(reservations, (r) => !r.canceledAt && !r.noShow),
    cancel: countWithFilter(reservations, (r) => !!r.canceledAt),
    noShow: countWithFilter(reservations, (r) => r.noShow),
  }
}


function countWithFilter(reservations: Reservation[], filter: (reservation: Reservation) => boolean) {
  const filtered = reservations.filter(filter);
  return {
    counts: filtered.length,
    people: filtered.reduce((a, b) => (a + b.adult + b.kid + b.infant), 0),
    reservations: filtered,
  }
}


const getDefaultFilter = (field: string) => ({field, operator: 'isAnyOf'});

const filterGen = (productNames: string[]) => function AdminFilter(props: GridHeaderFilterCellProps) {
  const {colDef} = props;
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const currentFieldFilters = React.useMemo(
    () => filterModel.items?.filter(({field}) => field === colDef.field),
    [colDef.field, filterModel.items],
  );


  const handleChange = React.useCallback(
    (event: SelectChangeEvent) => {
      if (!event.target.value) {
        if (currentFieldFilters[0]) {
          apiRef.current.deleteFilterItem(currentFieldFilters[0]);
        }
        return;
      }
      apiRef.current.upsertFilterItem({
        ...(currentFieldFilters[0] ?? getDefaultFilter(colDef.field)),
        value: event.target.value,
      });
    },
    [apiRef, colDef.field, currentFieldFilters],
  );

  const value = currentFieldFilters[0]?.value ?? [];
  const label = 'is any of';

  return (
    <FormControl variant="standard" sx={{m: 1, minWidth: 120}} fullWidth>
      <InputLabel id="select-is-admin-label">Select</InputLabel>
      <Select
        labelId="select-is-admin-label"
        id="select-is-admin"
        multiple
        value={value}
        onChange={handleChange}
        label={'Select'}
      >
        {
          productNames.map(p => (
            <MenuItem key={p} value={p}>{p}</MenuItem>
          ))
        }

      </Select>
    </FormControl>
  );
}


function MemoCard(props: { date: string }) {
  const {date} = props;
  const {
    data: notes,
    setData: setNotes,
  } = useListen<string[]>(`note/${date}`);

  const handleNoteChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const idx = Number.parseInt(event.target.name);
    const value = event.target.value;
    setNotes((notes) => {
      const updates = [...notes ?? []];
      updates[idx] = value;
      // if (updates[0] === undefined) updates[0] = 'Last Min 오픈: \n' + 'Last Min 10시 후 예약: '
      if (date) {
        updateRealtime(`note/${date}`, {[idx]: value})
          .then(() => {
            const changes = `Reservations note ${idx + 1} changed ${date}:[${notes?.[idx] ?? ''}=>${value}]`
            logAction('RESERVATION', 'UPDATE RESERVATIONS NOTE', `note/${date}`, changes, {
              date,
              note: value
            })
          })
          .catch(console.error);
      }
      return updates
    });
  }
  return (
    <Card>
      <CardContent>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={6}
          >
            <TextField
              fullWidth
              multiline
              id={date + '/0'}
              name={'0'}
              label={'Note'}
              variant={'outlined'}
              placeholder={'Note 1'}
              value={notes?.[0] ?? 'Last Min 오픈:' + '\n'
                + '[Klook]:' + '\n'
                + '[KK]:' + '\n'
                + '[VI]:' + '\n'
                + '[GG]:' + '\n'
                + '\n'
                + 'Last Min 10시 후 예약:' + '\n'
                + '[Klook]:' + '\n'
                + '[KK]:' + '\n'
                + '[VI]:' + '\n'
                + '[GG]:' + '\n'
              }
              onChange={handleNoteChange}
              rows={5}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <TextField
              fullWidth
              multiline
              id={date + '/1'}
              name={'1'}
              label={'Note'}
              variant={'outlined'}
              placeholder={'Note 2'}
              value={notes?.[1] ?? ''}
              onChange={handleNoteChange}
              rows={5}
            />
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  )
}


const ReservationServiceTabs = ['Reservation', 'Double Check', 'Count Option'];

export default function () {
  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([dayjs(), dayjs()]);
  const [tempDateRange, setTempDateRange] = React.useState<DateRange<Dayjs>>([dayjs(), dayjs()]);
  const formatDateRanges = dateRange.map(djs => (djs ?? dayjs()).format('YYYY-MM-DD'));
  const [reservationId, setReservationId] = useState<string | null>(null);
  const [newReservation, setNewReservation] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(true);
  const [copyReservation, setCopyReservation] = useState<Reservation | null>(null);
  const [loadKey, setLoadKey] = useState<string>(Date.now() + '');
  const [tab, setTab] = useState<number>(0);
  const [areaTab, setAreaTab] = useState<string>(ALL);
  const [keyword, setKeyword] = useState<string>('');
  const [serviceTab, setServiceTab] = useState<string>('Reservation');

  // const {
  //     data: reservations, setData: setReservations,loading
  // } = useQueryDocs<Reservation>('reservation', [['date', ">=", formatDateRanges[0]!], ['date', '<=', formatDateRanges[1]!]], formatDateRanges.join('-') + loadKey);
  //
  //


  const {
    data: _reservations, setData: setReservations, loading
  } =
    (dateRange[0]?.diff(dateRange[1], 'months') ?? 0) > 6 || serviceTab === 'doubleCheck'
      ?
      useQueryDocs<Reservation>('reservation', [['date', ">=", formatDateRanges[0]!], ['date', '<=', formatDateRanges[1]!]], formatDateRanges.join('-') + loadKey)
      : useQueryListen<Reservation>('reservation', [['date', ">=", formatDateRanges[0]!], ['date', '<=', formatDateRanges[1]!]], formatDateRanges.join('-') + loadKey);

  const {
    data: products,
  } = useRead<{ [productId: string]: Product }>('/product');
  const {
    data: pickups,
  } = useRead<{ [area: string]: { [pickupName: string]: Pickup } }>('/pickup');
  const {
    data: notes,
    setData: setNotes,
  } = useRead<string[]>(`note/${dateRange[0]?.format('YYYY-MM-DD')}`);

  const areaTabs = [ALL, ...Object.keys(pickups ?? {}).map(t => t.charAt(0).toUpperCase() + t.slice(1)).sort((a, b) => a.toLowerCase().startsWith('seoul') ? -1 : 1)];
  const {reservations, areaTabCounts} = _reservations.reduce((result, reservation) => {
    const matchedTab = areaTabs.find(t => reservation.productId.toLowerCase().includes(t.toLowerCase())) || ALL;
    result.areaTabCounts[matchedTab] += 1;
    result.areaTabCounts[ALL] += 1;
    if (areaTab === ALL || areaTab === matchedTab) {
      const product = products?.[reservation.productId];
      reservation.product = product?.name ?? reservation.product;
      result.reservations.push(reservation)
    }
    return result;
  }, {areaTabCounts: Object.fromEntries(areaTabs.map(t => [t, 0])), reservations: []} as {
    areaTabCounts: { [area: string]: number },
    reservations: Reservation[]
  })

  const aggregation = aggregateReservations(reservations ?? [], products ?? {});

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const handleChangeAreaTab = (_: any, value: string) => {
    setAreaTab(value);
  }

  const handleChangeServiceTab = (_: any, value: string) => {
    setServiceTab(value);
  }

  const handleDateRange = (dr: DateRange<Dayjs>) => {
    setDateRange(dr);
  }
  const handleTempDateRange = (dr: DateRange<Dayjs>) => {
    setTempDateRange(dr);
  }
  const handleConfirmDateRange = () => {
    const dateRanges = tempDateRange.map((d) => d?.format('YYYY-MM-DD'));
    logAction('RESERVATION', 'RETRIEVE RESERVATION', dateRanges.join(' ~ '), `Retrieve Reservations from ${dateRanges[0]} to ${dateRanges[1]}`, {dateRanges})
    setDateRange(tempDateRange);
  }
  const handleDateClick = (date: Date) => {
    setDateRange([dayjs(date), dayjs(date)]);
  }

  const handlePreDate = () => {
    setDateRange([dateRange[0]?.subtract(1, 'day') ?? dayjs(), dateRange[1]?.subtract(1, 'day') ?? dayjs()]);
  }

  const handleNextDate = () => {
    setDateRange([dateRange[0]?.add(1, 'day') ?? dayjs(), dateRange[1]?.add(1, 'day') ?? dayjs()]);
  }

  const handleClick = (row: GridRowParams) => {
    setReservationId((row as unknown as Reservation).id)
  }


  const handleToggleBuilder = (id: string, property: 'check' | 'noShow' | 'star') => (...any: any[]) => {
    // setReservations((reservations) => {
    //     const toggledReservation = reservations.find((reservation) => reservation.id === id);
    //     if (!toggledReservation) return reservations;
    //
    //     toggledReservation[property] = !toggledReservation[property];
    //
    //     const update = {[property]: toggledReservation[property]};
    //
    //     updateFireStore('reservation', toggledReservation.id, update).catch(console.error);
    //
    //     reservations[reservations.findIndex((reservation) => reservation.id === id)] = toggledReservation
    //
    //     return [...reservations];
    // });


    const toggledReservation = reservations.find((reservation) => reservation.id === id);
    if (!toggledReservation) return reservations;

    toggledReservation[property] = !toggledReservation[property];

    reservations[reservations.findIndex((reservation) => reservation.id === id)] = toggledReservation
    setReservations([...reservations]);

    const update = {[property]: toggledReservation[property]};
    updateFireStore('reservation', toggledReservation.id, update).catch(console.error);
  }

  const handleStarRows = () => {
    if (rowSelectionModel.length === 0) {
      return;
    }
    updateBatchFireStore('reservation', rowSelectionModel as string[], {'star': true}).catch(console.error);
  }

  const handleDestarRows = () => {
    if (rowSelectionModel.length === 0) {
      return;
    }
    updateBatchFireStore('reservation', rowSelectionModel as string[], {'star': false}).catch(console.error);
  }

  const handleEditMode = (_: any, checked: boolean) => {
    setEdit(checked);
  }


  const handleSetMultipleReservationMemos = () => {
    const memo = window.prompt('메모를 입력해주세요');
    if (memo && window.confirm(`선택하신 예약들에 메모를 "${memo}"를 쓰시겠습니까?`)) {
      const overwrite = window.confirm('덮어쓰시겠습니까? (취소 - 앞에쓰기)');
      Promise.all(selectedReservations.map(async (r) => {
        const newMemo = overwrite ? memo : memo + '\n' + r.memo;
        return updateFireStore("reservation", r.id, {memo: newMemo})
      }))
        .then(() => {
          logAction('RESERVATION', 'UPDATE RESERVATION',
            `reservations:${selectedReservations.map((r) => r.id).join(', ')}`,
            `Update ${selectedReservations.length} reservation memos, (${formatDateRanges.join('-')})`,
            {
              memo,
              reservationAgencyCodes: selectedReservations.map(r => r.agencyCode),
              reservationIds: selectedReservations.map(r => r.id)
            })
        })
        .catch((e) => {
          console.error(e);
          window.alert('반영 중 에러가 발생했습니다.');
        })
    }
  }


  const actions: GridColDef[] = [
    {
      field: 'star',
      type: 'actions',
      headerName: 'Star',
      minWidth: 30,
      align: 'center',
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={params.row.star ? <StarIcon color={'primary'} fontSize={'large'}/> :
            <StarOutlinedIcon fontSize={'large'}/>}
          onClick={handleToggleBuilder(params.id + '', 'star')}
          label={'star'}
        />
      ]
    },
    {
      field: 'noShow',
      type: 'actions',
      headerName: 'No Show',
      minWidth: 30,
      align: 'center',
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={params.row.noShow ? <NoShowIcon color={"primary"} fontSize={'large'}/> :
            <NoShowOutlinedIcon fontSize={'large'}/>}
          onClick={handleToggleBuilder(params.id + '', 'noShow')}
          label={'noShow'}
        />
      ]
    },
    {
      field: 'check',
      type: 'actions',
      headerName: 'Check',
      minWidth: 30,
      align: 'center',
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={params.row.check ? <CheckCircleIcon color={"primary"} fontSize={'large'}/> :
            <CheckCircleOutlinedIcon fontSize={'large'}/>}
          onClick={handleToggleBuilder(params.id + '', 'check')}
          label={'check'}
        />
      ]
    },
  ]
  const reservationRows =
    tab === 0
      ? aggregation.present
      : tab === 1
        ? aggregation.check
        : tab === 2
          ? aggregation.star
          : tab === 3
            ? aggregation.noStar
            : tab === 4
              ? aggregation.noShow
              : tab === 5
                ? aggregation.cancel
                : tab === 6
                  ? aggregation.all
                  : tab === 7
                    ? aggregation.outsource
                    : aggregation.total;


  const productNames = reservationRows.reduce((s, r) => {
    s.add(r.product);
    return s;
  }, new Set<string>());
  const selectedReservations = reservations.filter((r) => rowSelectionModel.includes(r.id));
  const ProductFilter = filterGen([...productNames.values()].sort((a, b) => a > b ? 1 : -1));
  const defaultColumns: GridColDef[] = [
    ...actions,
    {field: 'memo', headerName: 'MEMO', minWidth: 300, align: 'center', headerAlign: 'center'},
    {field: 'date', headerName: 'DATE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {
      field: 'product',
      type: 'singleSelect',
      headerName: 'PRODUCT',
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      renderHeaderFilter: (params) => <ProductFilter {...params}/>
    },
    {field: 'pickupPlace', headerName: 'PICKUP', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'people', headerName: 'PEOPLE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'option', headerName: 'OPTION', minWidth: 700, align: 'center', headerAlign: 'center'},
    {field: 'stroller', headerName: 'STROLLER', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'clientName', headerName: 'NAME', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'nationality', headerName: 'NATIONALITY', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'language', headerName: 'LANGUAGE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'agency', headerName: 'AGENCY', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'agencyCode', headerName: 'AGENCY CODE', minWidth: 200, align: 'center', headerAlign: 'center'},
  ];

  const cancelColumn: GridColDef[] = [
    {field: 'memo', headerName: 'MEMO', minWidth: 300, align: 'center', headerAlign: 'center'},
    {field: 'date', headerName: 'DATE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'canceledAt', headerName: 'CANCEL', minWidth: 100, align: 'center', headerAlign: 'center'},
    {
      field: 'product', headerName: 'PRODUCT', minWidth: 200, align: 'center', headerAlign: 'center',
      renderHeaderFilter: (params) => <ProductFilter {...params}/>
    },
    {field: 'pickupPlace', headerName: 'PICKUP', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'people', headerName: 'PEOPLE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'option', headerName: 'OPTION', minWidth: 700, align: 'center', headerAlign: 'center'},
    {field: 'stroller', headerName: 'STROLLER', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'clientName', headerName: 'NAME', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'nationality', headerName: 'NATIONALITY', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'language', headerName: 'LANGUAGE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'agency', headerName: 'AGENCY', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'agencyCode', headerName: 'AGENCY CODE', minWidth: 200, align: 'center', headerAlign: 'center'},
  ];

  const allColumn: GridColDef[] = [
    ...actions,
    {field: 'memo', headerName: 'MEMO', minWidth: 300, align: 'center', headerAlign: 'center'},
    {field: 'date', headerName: 'DATE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'canceledAt', headerName: 'Cancel', minWidth: 100, align: 'center', headerAlign: 'center'},
    {
      field: 'product', headerName: 'PRODUCT', minWidth: 200, align: 'center', headerAlign: 'center',
      renderHeaderFilter: (params) => <ProductFilter {...params}/>
    },
    {field: 'pickupPlace', headerName: 'PICKUP', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'people', headerName: 'PEOPLE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'option', headerName: 'OPTION', minWidth: 700, align: 'center', headerAlign: 'center'},
    {field: 'stroller', headerName: 'STROLLER', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'clientName', headerName: 'NAME', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'nationality', headerName: 'NATIONALITY', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'language', headerName: 'LANGUAGE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'agency', headerName: 'AGENCY', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'agencyCode', headerName: 'AGENCY CODE', minWidth: 200, align: 'center', headerAlign: 'center'},
  ];


  const columns =
    tab === 5 || tab == 7 ?
      cancelColumn :
      tab === 6 ?
        allColumn :
        defaultColumns

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          px: 8
        }}
      >
        <Box sx={{
          position: 'fixed',
          display: 'inline-flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bottom: 0,
          left: '50%',
          transform: 'translate(-50%, -1em)',
          zIndex: 999,
          backgroundColor: 'white',
          borderRadius: 5,
          boxShadow: 2,
          py: 2,
          px: 3
        }}>

          {
            rowSelectionModel.length > 0
              ? (
                <Box
                  component={Stack}
                  flexDirection={'row'}
                  gap={2}
                >
                  <ButtonBase
                    component={Stack}
                    flexDirection={'row'}
                    gap={1}
                    onClick={() => {
                      setRowSelectionModel([])
                    }}
                  >
                    <Box
                      color={'primary.main'}
                      display={'flex'}
                      alignItems={'center'}
                      gap={1}
                    >
                      <Icon>
                        <ReservationIcon/>
                      </Icon>
                      <Typography variant={'h6'} color={'inherit'}>
                        {selectedReservations.reduce((result, a) => result + 1, 0).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box
                      color={'accentPrimary.main'}
                      display={'flex'}
                      alignItems={'center'}
                      gap={1}
                    >
                      <Icon>
                        <PeopleIcon/>
                      </Icon>
                      <Typography variant={'h6'} color={'inherit'}>
                        {selectedReservations.reduce((result, a) => result + a.adult + a.kid + a.infant, 0).toLocaleString()}
                      </Typography>
                    </Box>
                  </ButtonBase>
                  <Box
                    component={Stack}
                    flexDirection={'row'}

                  >
                    <IconButton
                      onClick={handleStarRows}
                      color={'primary'}
                    >
                      <StarIcon/>
                    </IconButton>
                    <IconButton
                      onClick={handleDestarRows}
                      color={'primary'}
                    >
                      <StarOutlinedIcon/>
                    </IconButton>
                  </Box>

                  <Box>
                    <IconButton onClick={handleSetMultipleReservationMemos}>
                      <NoteIcon/>
                    </IconButton>
                  </Box>
                </Box>
              )
              : (
                <Button
                  fullWidth
                  variant={'text'}
                  size={'large'}
                  startIcon={<FiberNewIcon/>}
                  onClick={() => setNewReservation(true)}
                >
                  새 예약
                </Button>)
          }
        </Box>
        <>
          <ButtonBase
            disableTouchRipple
            onClick={handlePreDate}
            sx={{
              position: 'fixed',
              top: '50%',
              left: '0.5em',
              transform: 'translateY(-50%)',
              zIndex: 1,
            }}
          >
            <ArrowLeftIcon
              sx={{
                width: '2em',
                height: '2em'
              }}
            />
          </ButtonBase>
          <ButtonBase
            disableTouchRipple
            onClick={handleNextDate}
            sx={{
              position: 'fixed',
              top: '50%',
              right: '0.5em',
              transform: 'translateY(-50%)',
              zIndex: 1,
            }}
          >
            <ArrowRightIcon
              sx={{
                width: '2em',
                height: '2em'
              }}
            />
          </ButtonBase>
        </>
        <Card
          sx={{
            display: 'inline-block',
            flex: 0
          }}
        >
          <CardContent sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            color: theme.palette.text.primary,
            padding: '8px !important',
          })}>
            <DateTabs date={dateRange[0]?.toDate() ?? null} onChange={(date) => {
              setDateRange([dayjs(date), dayjs(date)]);
              setTempDateRange([dayjs(date), dayjs(date)]);
            }}/>
            <Box
              display={'flex'}
              gap={1}
            >
              <DateRangePickerValue onChange={handleTempDateRange} dateRange={dateRange}/>
              {
                loading ?
                  <CircularProgress/>
                  : <Button onClick={handleConfirmDateRange} variant={'contained'}
                            disabled={dateRange.map(d => d?.format('YYYY-MM-DD')).join() === tempDateRange.map(d => d?.format('YYYY-MM-DD')).join()}>
                    Confirm
                  </Button>

              }
            </Box>
          </CardContent>
        </Card>
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Tabs onChange={handleChangeAreaTab} value={areaTab}>
            {
              areaTabs.map((t) => (
                <Tab label={`${t}(${areaTabCounts[t]})`} value={t} id={'tab-area-' + t}
                     key={'tab-area-' + t}/>
              ))
            }
          </Tabs>
          <Tabs onChange={handleChangeServiceTab} value={serviceTab}>
            {
              ReservationServiceTabs.map(t => (
                <Tab
                  label={t.toUpperCase()}
                  value={t}
                  id={'tab-service-' + t}
                  key={'tab-service-' + t}/>
              ))
            }
          </Tabs>
        </Stack>
        {
          dateRange[0] && (dateRange[0].format('YYYY-MM-DD') === dateRange[1]?.format('YYYY-MM-DD'))
            ? <MemoCard date={dateRange[0].format('YYYY-MM-DD')}/>
            : null
        }

        {
          serviceTab === 'Double Check'
            ? (
              <>
                <DoubleCheck
                  key={(formatDateRanges?.join('-') ?? '') + 'doubleCheck'}
                  loadKey={loadKey}
                  onRefresh={() => setLoadKey(Date.now() + '')}
                  reservationRows={reservationRows}
                  onClickReservationRow={handleClick}
                  onRowSelectionModel={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  rowSelectionModel={rowSelectionModel}
                />
              </>
            )
            : serviceTab === 'Count Option'
              ? (
                <>
                  <CountOptions
                    key={(formatDateRanges?.join('-') ?? '') + 'countOptions'}
                    loadKey={loadKey}
                    onRefresh={() => setLoadKey(Date.now() + '')}
                    reservationRows={reservationRows}
                    onClickReservationRow={handleClick}
                    onRowSelectionModel={(newRowSelectionModel) => {
                      setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                  />
                </>
              )
              : (
                <>
                  {/*reservation controller*/}
                  <Stack
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                  >
                    <Tabs onChange={(_, value) => setTab(value)} value={tab}>
                      <Tab label={`Present(${aggregation.present.length})`} id={'tab-present'}/>
                      <Tab label={`Check(${aggregation.check.length})`} id={'tab-check'}/>
                      <Tab label={`Star(${aggregation.star.length})`} id={'tab-star'}/>
                      <Tab label={`No Star(${aggregation.noStar.length})`} id={'tab-star'}/>
                      <Tab label={`No Show(${aggregation.noShow.length})`} id={'tab-noshow'}/>
                      <Tab label={`Cancel(${aggregation.cancel.length})`} id={'tab-cancel'}/>
                      <Tab label={`All(${aggregation.all.length})`} id={'tab-all'}/>
                      <Tab label={`OUTSOURCE(${aggregation.outsource.length})`} id={'tab-outsource'}/>
                      <Tab label={`Total(${aggregation.total.length})`} id={'tab-total'}/>
                    </Tabs>
                    <Stack flexDirection={'row'} gap={0.5}>
                      <Switch onChange={handleEditMode} checked={edit}/>
                      {/*<FormControlLabel control={} label={''} />*/}
                      <TourReservationList dateRanges={formatDateRanges} reservations={reservations}
                                           products={products}/>
                    </Stack>
                  </Stack>

                  <Card>
                    {
                      edit
                        ? (
                          <CardContent>
                            <ReservationCharts reservations={selectedReservations} products={products ?? {}}/>
                          </CardContent>
                        )
                        : null
                    }
                    <CardContent>
                      <Box sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end'
                      }}>
                        <TextField
                          sx={{
                            width: '300px'
                          }}
                          variant={"standard"}
                          placeholder={"search"}
                          value={keyword}
                          onChange={(e) => setKeyword(e.target.value ?? null)}
                        />
                      </Box>
                      <Box
                        sx={
                          edit
                            ? {height: '70vh', mt: 2,}
                            : {mt: 2,}
                        }
                      >
                        <DataGridPro
                          checkboxSelection
                          unstable_headerFilters
                          rows={
                            reservationRows
                              .filter((r) => {
                                return !keyword || `${r.clientName}${r.agencyCode}${r.email}${r.tel}${r.messenger}${r.id}${r.memo}`.toLowerCase().includes(keyword.toLowerCase())
                              })
                          }
                          columns={columns}
                          onRowClick={handleClick}
                          onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                          }}
                          rowSelectionModel={rowSelectionModel}
                          disableRowSelectionOnClick
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </>
              )

        }


      </Box>
      {
        reservationId
          ? <ReservationModal
            reservationId={reservationId}
            onCopy={(reservation) => {
              setCopyReservation(reservation)
            }}
            onClose={(update) => {
              setReservationId(null);
              if (update) {
                setLoadKey(Date.now() + '');
              }
            }}
          />
          : null
      }
      {
        newReservation || copyReservation
          ? <CreateReservationModal
            defaultReservation={copyReservation}
            onClose={(update) => {
              setNewReservation(false);
              setCopyReservation(null);
              if (update)
                setLoadKey(Date.now() + '')
            }}
          />
          : null
      }
    </>
  )
}

function aggregateReservations(reservations: Reservation[], products: {
  [productId: string]: Product
} | null) {
  return reservations
    .reduce((result, reservation, idx) => {
        const people = reservation.adult + reservation.kid + reservation.infant;
        const row = {
          id: reservation.id,
          memo: reservation.memo,
          date: reservation.date,
          product: products?.[reservation.productId]?.name ?? reservation.product,
          pickupPlace: reservation.pickupPlace,
          people: `${people}(${reservation.adult}/${reservation.kid}/${reservation.infant})`,
          option: reservation.option && reservation.option.length > 0 ? reservation.option.map((option) => `${option.option}(${option.people})`).sort().join(',\n') : '',
          clientName: reservation.clientName,
          nationality: reservation.nationality,
          language: (reservation?.language ?? 'English').split(/- /g)[0].replace(/english/gi, 'EN').replace(/chinese/gi, 'CN').replace(/japanese/gi, 'JP').replace(/korean/gi, 'KO'),
          agency: reservation.agency,
          stroller: (!!reservation.stroller) ? 'O' : 'X',
          agencyCode: reservation.agencyCode,
          star: reservation.star,
          noShow: reservation.noShow,
          check: reservation.check,
          email: reservation.email ?? '',
          tel: reservation.tel ?? '',
          messenger: reservation.messenger ?? '',
          canceledAt: reservation.canceledAt ? dayjs(reservation.canceledAt).format('YYYY-MM-DD') : undefined,
        };

        result.total.push(row)

        const isOutsourced = reservation.id.toUpperCase().startsWith('KTOS');
        if (isOutsourced) {
          result.outsource.push(row)
          return result;
        }

        if (!reservation.canceledAt) {
          result.all.push(row)
        }
        if (!reservation.noShow && !reservation.canceledAt) {
          result.present.push(row)
        }
        if (reservation.noShow && !reservation.canceledAt) {
          result.noShow.push(row)
        }
        if (reservation.star && !reservation.canceledAt) {
          result.star.push(row)
        }
        if (!reservation.star && !reservation.canceledAt) {
          result.noStar.push(row)
        }
        if (reservation.check && !reservation.canceledAt) {
          result.check.push(row)
        }

        if (reservation.canceledAt) {
          result.cancel.push(row)
        }
        return result;
      },
      {
        total: [],
        all: [],
        present: [],
        star: [],
        noStar: [],
        check: [],
        noShow: [],
        cancel: [],
        outsource: [],
      } as ReservationRows
    )
}


function TourReservationList(props: {
  dateRanges: string[],
  reservations: Reservation[],
  products: { [productId: string]: Product } | null
}) {
  const [loading, setLoading] = useState(false);
  const [startAt, endAt] = props.dateRanges;
  const reservationMap = new Map((props.reservations ?? []).map((r) => [r.id, r]));
  const schema = [
    {
      column: 'Date',
      value: (r: any) => r.date,
    },
    {
      type: Date,
      column: 'Reservation Date',
      value: (r: any) => r.reservedDate,
      format: 'YYYY-MM-DD',
    },
    {
      type: Date,
      column: 'Reservation Time',
      value: (r: any) => r.reservedDate,
      format: 'hh:mm',
    },
    {
      column: 'ID',
      value: (r: any) => r.id,
    },
    {
      column: 'Agency',
      value: (r: any) => r.agency,
    },
    {
      column: 'Agency Code',
      value: (r: any) => r.agencyCode,
    },
    {
      column: 'Area',
      value: (r: any) => r.area,
    },
    {
      column: 'Product',
      value: (r: any) => r.product,
    },
    {
      column: 'Team',
      value: (r: any) => r.teamIdx,
      type: Number
    },
    {
      column: 'Guide',
      value: (r: any) => r.guide,
    },
    {
      column: 'Pickup',
      value: (r: any) => r.pickup,
    },
    {
      column: 'name',
      value: (r: any) => r.name,
    },
    {
      column: 'Nationality',
      value: (r: any) => r.nationality,
    },
    {
      column: 'Language',
      value: (r: any) => r.language,
    },
    {
      column: 'People',
      value: (r: any) => r.people,
      type: Number,
    },
    {
      column: 'Adult',
      value: (r: any) => r.adult,
      type: Number,
    },
    {
      column: 'Kid',
      value: (r: any) => r.kid,
      type: Number,
    },
    {
      column: 'Infant',
      value: (r: any) => r.infant,
      type: Number,
    },
    {
      column: 'Option',
      value: (r: any) => r.option,
    },
    {
      column: 'stroller',
      value: (r: any) => r.stroller,
    },
    {
      column: 'Email',
      value: (r: any) => r.email,
    },
    {
      column: 'Tel',
      value: (r: any) => r.tel,
    },
    {
      column: 'Messenger',
      value: (r: any) => r.messenger,
    },
    {
      column: 'Memo',
      value: (r: any) => r.memo,
    },

  ]

  const handleClick = useCallback(() => {
    const dateRanges = props.dateRanges.map(d => dayjs(d));
    const dateDiff = dateRanges[1].diff(dateRanges[0], 'days');
    const download = async () => {
      const reason = dateDiff > 7 ? window.prompt('다운로드 사유를 입력해주세요') ?? '' : '일반 다운로드';
      if (!reason) {
        window.alert('사유를 입력해주세요')
        return;
      }
      setLoading(true);
      const operations = await readWithKeyStartEndAt<Operation>('/operation', startAt, endAt);
      const totalRows = Object.values(operations ?? {}).flatMap((operation) => {
        const groupedTours = Object.values(operation?.tours ?? {}).reduce((result, tour) => {
          if (!result[tour.area]) result[tour.area] = [];
          if (!tour.teams || !Object.values(tour.teams)[0].reservations) return result;
          result[tour.area].push(tour);
          result[tour.area] = result[tour.area].sort(({idx: a}, {idx: b}) => a - b);
          return result;
        }, {} as { [area: string]: Tour[] });

        const rows = Object.entries(groupedTours).flatMap(([area, tours]) => {
          const reservationsByTour = tours.map(tour => {
            const reservationByTeam = Object.values(tour.teams ?? {}).map((team, idx) => {
              const teamIdx = idx + 1;
              const guide = team.guides?.map((g) => g.name).join(", ") ?? '';
              const reservations = Object.values(team.reservations ?? {}).map((reservation) => {
                return {
                  date: reservation.date,
                  reservedDate: new Date(reservationMap.get(reservation.id)?.reservedAt ?? ''),
                  id: reservation.id,
                  agency: reservation.agency,
                  agencyCode: reservation.agencyCode,
                  area: area,
                  product: props.products?.[reservation.productId]?.name ?? reservation.product,
                  teamIdx: teamIdx,
                  guide: guide,
                  pickup: reservation.pickupPlace,
                  name: reservation.clientName,
                  nationality: reservation.nationality,
                  language: reservation.language?.split('-')[0] ?? '',
                  people: reservation.adult + reservation.kid + reservation.infant,
                  adult: reservation.adult,
                  kid: reservation.kid,
                  infant: reservation.infant,
                  option: reservation.option && reservation.option.length > 0 ? reservation.option.map((option) => `${option.option}(${option.people})`).join(', ') : '',
                  email: reservation.email,
                  tel: reservation.tel,
                  messenger: reservation.messenger,
                  stroller: (!!reservation.stroller) ? 'O' : 'X',
                  memo: reservation.memo
                }
              });
              return reservations;
            });
            return reservationByTeam.flat(1);
          });
          return reservationsByTour.flat(2);
        });

        return rows;
      });

      logAction('RESERVATION', 'DOWNLOAD RESERVATION', [startAt, endAt].join(' ~ '), `Download Reservations from ${startAt} to ${endAt}`, {
        dateRange: [startAt, endAt],
        count: totalRows.length,
        reason
      })

      await writeXlsxFile(totalRows, {
        schema,
        fileName: `kint2-reservations.xlsx`
      })
    }

    download()
      .catch((e) => {
        console.error(e)
        alert('Fail on download excel');
      })
      .finally(() => setLoading(false));
  }, [startAt, endAt, setLoading, reservationMap]);

  return (
    <Button onClick={handleClick} variant={"outlined"}>
      {
        loading ? <CircularProgress size={32}/>
          : 'EXCEL'
      }
    </Button>
    // <Card>
    //     <CardContent>
    //         <DataGridPro columns={columns} rows={rows} slots={{toolbar: CustomToolbar}}/>
    //     </CardContent>
    // </Card>
  )
}

import * as XLSX from "xlsx";
import AgencyRow, {RawAgencyRow} from "./agency";
import {findSkiOptions} from "./ski";


const twoStepTours = [
  'Day Tour from Seoul: Eobi Ice Valley, The Garden of Morning Calm (Light Festival), Nami Island, Petite France & More | South Korea',
  '[Winter Only] Day Tour from Seoul: Eobi Ice Valley, The Garden of Morning Calm Lighting Festival, Nami Island, Petite France & More | South Korea',
  '[Winter Only] Day Tour from Seoul: Eobi Ice Valley, The Garden of Morning Calm (Light Festival), Nami Island, Petite France & More | South Korea'
]
const twoStepOptionPackages: { [key: string]: string | { [key: string]: string } } = {
  'A: Eobi Ice Valley (Optional) + Nami Island + The Garden of Morning Calm (Light Festival) + Petite France & Italian Village': {
    '[3 Spots] Nami Island + The Garden of Morning Calm (Light Festival) + Petite France & Italian Village': '남쁘아',
    '[4 Spots] Eobi Ice Valley + Nami Island + The Garden of Morning Calm (Light Festival) + Petite France & Italian Village': '(어)남쁘아'
  },

  'A: Eobi Ice Valley (Winter Only) + Nami Island + The Garden of Morning Calm (Light Festival) + Petite France & Italian Village': {
    '[3 Spots] Nami Island + The Garden of Morning Calm (Light Festival) + Petite France & Italian Village': '남쁘아',
    '[4 Spots] Eobi Ice Valley + Nami Island + The Garden of Morning Calm (Light Festival) + Petite France & Italian Village': '(어)남쁘아'
  },

  'B: Eobi Ice Valley (Optional) + Nami Island + The Garden of Morning Calm (Light Festival)': {
    '[2 Spots] Nami Island + The Garden of Morning Calm (Light Festival)': '남아',
    '[3 Spots] Eobi Ice Valley + Nami Island + The Garden of Morning Calm (Light Festival)': '(어)남아'
  },
  'B: Eobi Ice Valley (Winter Only) + Nami Island + The Garden of Morning Calm (Light Festival)': {
    '[2 Spots] Nami Island + The Garden of Morning Calm (Light Festival)': '남아',
    '[3 Spots] Eobi Ice Valley + Nami Island + The Garden of Morning Calm (Light Festival)': '(어)남아'
  },

  'C: Eobi Ice Valley (Optional) + Nami Island + The Garden of Morning Calm (Light Festival) + Gangchon Rail Bike': {
    '[3 Spots] Nami Island + The Garden of Morning Calm (Light Festival) + Gangchon Rail Bike': '레남아',
    '[4 Spots] Eobi Ice Valley + Nami Island + The Garden of Morning Calm (Light Festival) + Gangchon Rail Bike': '어레남아'
  },

  'C: Eobi Ice Valley (Winter Only) + Nami Island + The Garden of Morning Calm (Light Festival) + Gangchon Rail Bike': {
    '[3 Spots] Nami Island + The Garden of Morning Calm (Light Festival) + Gangchon Rail Bike': '레남아',
    '[4 Spots] Eobi Ice Valley + Nami Island + The Garden of Morning Calm (Light Festival) + Gangchon Rail Bike': '어레남아'
  },

  'D: Nami Island +The Garden of Morning Calm (Lighting Festival) + Gangchon Rail Bike + Petite France & Italian Village': '쁘남레아',

  'E: Eobi Ice Valley (Optional) + Alpaca World + Nami Island + The Garden of Morning Calm (Light Festival)': {
    '[3 Spots] Alpaca World + Nami Island + The Garden of Morning Calm (Light Festival)': '알남아',
    '[4 Spots] Eobi Ice Valley + Alpaca World + Nami Island + The Garden of Morning Calm (Light Festival)': '어알남아'
  },


  'E: Eobi Ice Valley (Winter Only) + Alpaca World + Nami Island + The Garden of Morning Calm (Light Festival)': {
    '[3 Spots] Alpaca World + Nami Island + The Garden of Morning Calm (Light Festival)': '알남아',
    '[4 Spots] Eobi Ice Valley + Alpaca World + Nami Island + The Garden of Morning Calm (Light Festival)': '어알남아'
  },

  'E: Eobi Ice Valley (Winter Only) +  Alpaca World + Nami Island + The Garden of Morning Calm (Light Festival)': {
    '[3 Spots] Alpaca World + Nami Island + The Garden of Morning Calm (Light Festival)': '알남아',
    '[4 Spots] Eobi Ice Valley + Alpaca World + Nami Island + The Garden of Morning Calm (Light Festival)': '어알남아'
  },

  'F: Alpaca World + Gangchon Rail Bike + Nami Island + The Garden of Morning Calm (Lighting Festival)': '알레남아',

  'G: Eobi Ice Valley + Nami Island + Gangchon Rail Bike': '어남레',

  'H: Eobi Ice Valley + Alpaca World + Nami Island + Gangchon Rail Bike': '어알남레'
}




export default async function parseKKDayFiles(file: File | Blob): Promise<AgencyRow[]> {
  const parsed = await parseFileData(file);
  const aggregation = parsed.reduce((result, row) => {
    const agency = 'KK';
    const agencyCode = cleanString(row['Booking no.']);
    const orderStatus = cleanString(row['Order Status']);
    if (!agencyCode) return result;
    if (orderStatus.includes('Canceled')) return result;

    const clientName = cleanString(row['Passport First Name (English)'] + ' ' + cleanString(row['Passport Surname Name (English)']));
    const twoStepProduct = checkTwoStepProducts(row['Product\'s Name']?.trim() ?? '', row['Package Name']?.trim() ?? '', row['Specification 1']?.trim() ?? '');
    const product = twoStepProduct ?? [cleanString(row['Product\'s Name']), cleanString(row['Package Name'])].join(' ');
    const people = toNumber(row['Quantity']);
    const date = cleanString(row['Departure Date']);
    const email = cleanString(row['Email Address']);
    const tel = cleanString(row['Buyer\'s Phone Number'])

    let option = cleanString(row['Package Name']);
    let specifications = [row['Specification 1'], row['Specification 2'], row['Specification 3']]
    if (twoStepProduct) {
      option = '';
      if(!specifications[0].match(/(Meeting|Pick|接送)/gi))
        specifications.shift();
    }

    const pickupPlace = cleanString(row['Pick-up location']) + specifications.join(' ');

    const language = cleanString(row['Tour Language']).replace(/zh.*/gi, 'CN').replace(/en.*/gi, 'EN');

    const options = [option, ...specifications]
      .filter(specification => specification && !(specification?.match(/(Meeting|Pick|接送)/gi)))
      .map(specification => {
        const optionable = /(?:.+?[：:])?(.+)/.exec(specification)?.[1]
        return optionable?.trim() ?? '';
      })
      .map(cleanString)
      .flatMap((specification) => findSkiOptions('KK', cleanString(row['Product\'s Name']), specification, cleanString(row['Package Name'])) ?? specification)
      .map((specification) => {
        const lowerSpecification = specification.toLowerCase();
        if (lowerSpecification.includes('early return')) {
          return 'EARLY RETURN' as string;
        }
        if (lowerSpecification.includes('late return')) {
          return 'LATE RETURN' as string;
        }
        if (lowerSpecification.includes('sky capsule')) {
          return 'CAPSULE' as string;
        }
        if (lowerSpecification.includes('t-money')) {
          return 'T-MONEY' as string;
        }
        return specification;
      });



    const obj: RawAgencyRow = {
      agency,
      agencyCode,
      clientName,
      language,
      product,
      date,
      people,
      email,
      tel,
      pickupPlace,
      stroller: 'X',
      options: options.map(option => ({option, people})),
    };
    const photo =  toNumber(row['Snapshot service deposit (per person)'] ?? '0');
    if(photo){
      obj.options.push({option:'PHOTOSHOOT', people:photo});
    }

    result[agencyCode] = obj;
    return result;
  }, {} as {
    [agencyCode: string]: RawAgencyRow
  });
  return Object.values(aggregation);
}

const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  const data = XLSX.utils.sheet_to_json(firstSheet, {range: 1}) as any[];
  return data;
}

function cleanString(data?: any): string {
  const escaped = data?.toString().replace(/.+[\]】]/gi, '');
  return escaped?.trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}

function checkTwoStepProducts(tourName: string, packageName: keyof typeof twoStepOptionPackages, specification: string) {
  if (twoStepTours.includes(tourName)) {
    const packages = twoStepOptionPackages[packageName];
    if (typeof packages === 'string') {
      return packages
    }
    return (packages?.[specification.replace('Options：', '').trim()] as string);
  }
}

import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {DateRange, DateRangePicker, SingleInputDateRangeField} from "@mui/x-date-pickers-pro";
import dayjs, {Dayjs} from "dayjs";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DateTabs from "../../components/DateTabs";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {useRead} from "../../hooks/realtime";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from '@mui/material/TableRow';
import TableCell from "@mui/material/TableCell";
import {queryWhereFireStore} from "../../hooks/firebase";
import {doc, getDocs, Query, Timestamp} from "firebase/firestore";
import {useAuth} from "../../hooks/auth";
import Modal from "@mui/material/Modal";
import {InputLabel, Select, SelectChangeEvent, Typography} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import {
  DataGridPro,
  GridColDef, gridFilterModelSelector,
  GridHeaderFilterCellProps,
  GridToolbarQuickFilter,
  useGridApiContext, useGridSelector
} from "@mui/x-data-grid-pro";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import ButtonBase from "@mui/material/ButtonBase";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const ALL = 'ALL';

function DateRangePickerValue(props: { dateRange: DateRange<Dayjs>, onChange: (dr: DateRange<Dayjs>) => void }) {
  const {dateRange: value, onChange: setValue} = props;
  return (
    <DateRangePicker
      format={'YY-MM-DD'}
      slots={{field: SingleInputDateRangeField}}
      value={value}
      onChange={(newValue) => setValue(newValue)}
    />
  );
}


export default function () {
  const {auth} = useAuth();
  const [row, setRow] = useState<any>(null);
  const [checked, onChecked] = useState<boolean>(true);
  const [keywords, setKeywords] = React.useState<string>('');
  const [searchLoading, setSearchLoading] = React.useState<boolean>(false);
  const [operator, setOperator] = React.useState<{ id: string, name: string }>({id: ALL, name: ALL})
  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([dayjs(), dayjs()]);
  const [tempDateRange, setTempDateRange] = React.useState<DateRange<Dayjs>>([dayjs(), dayjs()]);
  const {data: users} = useRead<any>('user');
  const operatorOptions = [{
    id: ALL,
    name: ALL
  }].concat((Object.values<any>(users ?? {}).filter(u => (u?.level ?? 99) < 1.5).map(u => ({name: u.name, id: u.id}))))
  const [actions, setActions] = useState<any[]>([]);
  const loading = false;

  React.useEffect(() => {
    setKeywords('');
    const adjustedDates = adjustDate(dateRange?.[0]?.toDate() ?? new Date(), dateRange?.[1]?.toDate() ?? new Date());
    const queries: [string, string, Date | Timestamp | string][] = [
      ['timestamp', '>=', Timestamp.fromDate(adjustedDates[0].toDate())],
      ['timestamp', '<', Timestamp.fromDate(adjustedDates[1].toDate())],
    ];

    if (operator.id !== ALL) queries.push(['user', '==', operator.id]);

    getDocs(queryWhereFireStore('action', queries)).then((d) => {
      setActions(
        d.docs.map(d => {
          return {...d.data(), id: d.id}
        })
      );
    });
  }, [operator.id, dateRange.map(d => d?.format('YYYY-MM-DD')).join('/')])

  React.useEffect(() => {
    if (keywords) {
      getDocs(queryWhereFireStore('action', [['others.reservation.agencyCode', '==', keywords]])).then((d) => {
        setActions(
          d.docs.map(d => {
            return {...d.data(), id: d.id}
          })
        );
      });
    }
  }, [keywords])

  const handleTempDateRange = (dr: DateRange<Dayjs>) => {
    setTempDateRange(dr);
  }

  const handleConfirmDateRange = () => {
    setDateRange(tempDateRange);
  }

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      setKeywords(event.currentTarget.value ?? '');
    }
  }
  const rows = actions
    .sort((a, b) => a.timestamp.seconds - b.timestamp.seconds)
    .map(a => {
      const userKey: string = a.user;
      const u = users?.[userKey];
      return ({
        id: a.id,
        name: u ? `${u.name}(${u.nameEn})` : a.user,
        domain: a.domain,
        action: a.action,
        detail: a.detail,
        timestamp: a.timestamp.toDate(),
        target: a.what,
        ignore: 0,
        log: a,
        startRow: null as any,
      })
    }).filter((row, idx, rows) => {
      const nextRow = rows[idx + 1]
      if (!nextRow) return true;
      const sameWriter = row.name === nextRow.name;
      const sameDomain = row.domain === nextRow.domain;
      const sameAction = row.action === nextRow.action;
      const sameTarget = row.target === nextRow.target;
      const sameTime = dayjs(row.timestamp).format('yyyy-mm-dd hh:mm') === dayjs(nextRow.timestamp).format('yyyy-mm-dd hh:mm');
      //Dangerous
      if (sameWriter && sameDomain && sameAction && sameTime && sameTarget) {
        nextRow.ignore = row.ignore + 1;
        nextRow.startRow = row.startRow ?? row;
        if (checked)
          return false;
        else
          return true;
      } else {
        nextRow.ignore = 0
        return true;
      }
    })

  const NameFilter = filterGen(Object.values(users ?? {}).filter((u: any) => (u.level ?? 99) < 1.5).map((u: any) => (`${u.name}(${u.nameEn})`) ?? '').sort((a, b) => a > b ? 1 : -1))
  const DomainFilter = filterGen(Array.from(new Set(actions.map(a => a.domain))).sort((a, b) => a > b ? 1 : -1))
  const ActionFilter = filterGen(Array.from(new Set(actions.map(a => a.action))).sort((a, b) => a > b ? 1 : -1))

  const columns: GridColDef[] = [
    {
      field: 'name', headerName: '이름', minWidth: 300, align: 'center', headerAlign: 'center',
      renderHeaderFilter: (params) => <NameFilter {...params}/>
    },
    {
      field: 'domain', headerName: '부분', minWidth: 300, align: 'center', headerAlign: 'center',
      renderHeaderFilter: (params) => <DomainFilter {...params}/>
    },
    {
      field: 'action', headerName: '작업', minWidth: 300, align: 'center', headerAlign: 'center',
      renderHeaderFilter: (params) => <ActionFilter {...params}/>,
      renderCell: (params) => params.row.ignore ? `${params.value}(${params.row.ignore})` : `${params.value}`,

    },
    {
      field: 'detail', headerName: '상세', minWidth: 1600, align: 'left', headerAlign: 'center',
      renderCell: (params) => {
        return params.row.startRow ? `${params.row.startRow.detail} => ${params.row.detail}` : `${params.row.detail}`;
      }
    },
    {
      field: 'timestamp', headerName: '날짜', minWidth: 300, align: 'center', headerAlign: 'center',
      renderCell: (params) => (params.value.toLocaleString())
    },
  ]

  const handlePreDate = () => {
    setDateRange([dateRange[0]?.subtract(1, 'day') ?? dayjs(), dateRange[1]?.subtract(1, 'day') ?? dayjs()]);
  }

  const handleNextDate = () => {
    setDateRange([dateRange[0]?.add(1, 'day') ?? dayjs(), dateRange[1]?.add(1, 'day') ?? dayjs()]);
  }

  return (
    <>
      <>
        <ButtonBase
          disableTouchRipple
          onClick={handlePreDate}
          sx={{
            position: "fixed",
            top: "50%",
            left: "0.5em",
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          <ArrowLeftIcon
            sx={{
              width: "2em",
              height: "2em",
            }}
          />
        </ButtonBase>
        <ButtonBase
          disableTouchRipple
          onClick={handleNextDate}
          sx={{
            position: "fixed",
            top: "50%",
            right: "0.5em",
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          <ArrowRightIcon
            sx={{
              width: "2em",
              height: "2em",
            }}
          />
        </ButtonBase>
      </>
      <Box
        sx={{
          px: 8,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={(theme) => ({
                mb: 4,
                px: 4,
                backgroundColor: theme.palette.background.paper,
                borderRadius: 8
              })}
            >
              {
                searchLoading
                  ? <CircularProgress/>
                  : <InputBase
                    fullWidth
                    autoFocus
                    placeholder={'Agency Code Only'}
                    onKeyDown={handleEnter}
                    sx={{
                      height: '56px'
                    }}
                  />
              }
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Card
              sx={{
                display: 'inline-block',
                width: '100%',
              }}
            >
              <CardContent sx={(theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                color: theme.palette.text.primary,
                padding: '8px !important',
              })}>
                <DateTabs
                  backward
                  date={dateRange[0]?.toDate() ?? null}
                  length={7}
                  onChange={(date) => {
                    setDateRange([dayjs(date), dayjs(date)]);
                    setTempDateRange([dayjs(date), dayjs(date)]);
                  }}/>
                <Box
                  display={'flex'}
                  gap={1}
                >
                  <DateRangePickerValue onChange={handleTempDateRange} dateRange={dateRange}/>
                  {
                    loading ?
                      <CircularProgress/>
                      : <Button onClick={handleConfirmDateRange} variant={'contained'}
                                disabled={dateRange.map(d => d?.format('YY-MM-DD')).join() === tempDateRange.map(d => d?.format('YY-MM-DD')).join()}>
                        Confirm
                      </Button>
                  }
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card>
              <CardContent sx={(theme) => ({
                color: theme.palette.text.primary,
                padding: '8px !important',
              })}>

                <Autocomplete
                  options={operatorOptions}
                  getOptionLabel={(option) => option.name}
                  value={operator}
                  onChange={(_, operatorOption) => {
                    if (operatorOption) {
                      setOperator(operatorOption);
                    }
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{backgroundColor: 'white'}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label={'Operator'}
                      InputLabelProps={{shrink: true}}
                    />
                  )}/>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Switch checked={checked} onChange={(_, value) => onChecked(value)}/>

            <DataGridPro
              slots={{
                toolbar: (...props) => (
                  <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'flex-end',
                    py: 2,
                    px: 2
                  }}>
                    <GridToolbarQuickFilter
                      {...props}
                      fullWidth
                    />
                  </Box>
                )
              }}
              rows={rows}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [{field: 'timestamp', sort: 'desc'}],
                },
              }}
              onRowClick={(r) => {
                setRow(r.row);
              }}
              disableRowSelectionOnClick
              unstable_headerFilters
            />
          </Grid>
        </Grid>
      </Box>
      {
        row ?
          <Modal open={!!row} sx={{
            p: 12
          }}
                 onClose={() => setRow(null)}
          >
            <Paper sx={{
              maxHeight: '80vh',
              overflowY: 'scroll',
              p: 2
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={'bold'}>
                    {
                      row.target ?? row.log.target ?? ''
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {
                    row.log.detail
                  }
                </Grid>
                <Grid item xs={(row.log.value?.from && row.log.value.to) || (row.startRow) ? 6 : 12}>
                  <Typography variant={'h5'}>
                    {
                      row.log.value?.from && row.log.value.to || row.startRow
                        ? 'FROM'
                        : 'VALUE'
                    }
                  </Typography>
                  <Typography whiteSpace={'pre-wrap'}>
                    {
                      row.startRow?.log.value?.from || row.startRow?.log.value.note || row.startRow?.log.value.memo
                        ? row.startRow?.log.value.memo ?? row.startRow?.log.value.note
                        : Object.entries(row.log.value?.from ?? row.log.value ?? {})
                          .sort((a, b) => a[0] < b[0] ? -1 : 0)
                          .map((e) => {
                            return `${e[0]}: ${JSON.stringify(e[1], null, 2)}`
                          })
                          .join('\n')
                    }
                  </Typography>
                </Grid>
                {
                  (row.log.value?.from && row.log.value.to) || (row.startRow) ?
                    <Grid item xs={(row.log.value?.from && row.log.value.to) || (row.startRow) ? 6 : 12}>
                      <Typography variant={'h5'}>
                        TO
                      </Typography>
                      <Typography whiteSpace={'pre-wrap'}>
                        {
                          row.startRow
                            ? row.log.value.note ?? row.log.value.memo ?? JSON.stringify(row.log.value)
                            : Object.entries(row.log.value?.to ?? {})
                              .sort((a, b) => a[0] < b[0] ? -1 : 0)
                              .map((e) => (
                                <>
                                  <Typography component={'span'}
                                              color={JSON.stringify(e[1]) !== JSON.stringify(row.log.value?.from[e[0]]) ? 'error' : 'inherit'}>
                                    {e[0]}
                                  </Typography>
                                  :
                                  <Typography component={'span'}
                                              color={JSON.stringify(e[1]) !== JSON.stringify(row.log.value?.from[e[0]]) ? 'error' : 'inherit'}>
                                    {JSON.stringify(e[1], null, 2)}
                                  </Typography>
                                  <br/>
                                </>
                              ))
                        }
                      </Typography>
                    </Grid>
                    : null
                }
              </Grid>
            </Paper>
          </Modal>
          : null
      }
    </>
  )
}

function adjustDate(from: Date, to?: Date) {
  const startDate = dayjs(from);
  const endDate = dayjs(to ?? from);
  const modifiedStartDate = startDate.hour(0).minute(0)
  const modifiedEndDate = endDate.hour(23).minute(59).add(1, 'minutes');

  return [modifiedStartDate, modifiedEndDate];
}


const getDefaultFilter = (field: string) => ({field, operator: 'isAnyOf'});

const filterGen = (productNames: string[]) => function AdminFilter(props: GridHeaderFilterCellProps) {
  const {colDef} = props;
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const currentFieldFilters = React.useMemo(
    () => filterModel.items?.filter(({field}) => field === colDef.field),
    [colDef.field, filterModel.items],
  );


  const handleChange = React.useCallback(
    (event: SelectChangeEvent) => {
      if (!event.target.value) {
        if (currentFieldFilters[0]) {
          apiRef.current.deleteFilterItem(currentFieldFilters[0]);
        }
        return;
      }
      apiRef.current.upsertFilterItem({
        ...(currentFieldFilters[0] ?? getDefaultFilter(colDef.field)),
        value: event.target.value,
      });
    },
    [apiRef, colDef.field, currentFieldFilters],
  );

  const value = currentFieldFilters[0]?.value ?? [];
  const label = 'is any of';

  return (
    <FormControl variant="standard" sx={{m: 1, minWidth: 120}} fullWidth>
      <InputLabel id="select-is-admin-label">Select</InputLabel>
      <Select
        labelId="select-is-admin-label"
        id="select-is-admin"
        multiple
        value={value}
        onChange={handleChange}
        label={'Select'}
      >
        {
          productNames.map(p => (
            <MenuItem key={p} value={p}>{p}</MenuItem>
          ))
        }

      </Select>
    </FormControl>
  );
}
